<template>
  <el-dialog v-model="visible" title="开工信息">
    <el-card>
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <!-- <el-form-item label="施工许可编号" prop="permitNo">
            <el-input v-model="form.permitNo" placeholder="施工许可编号"></el-input>
          </el-form-item>
          <el-form-item label="合同金额(万元)" prop="amount">
            <el-input v-model="form.amount" placeholder="合同金额(万元)"
              @input="(v) => inputChangeEmptyToNull(v, form, 'amount')"></el-input>(万元)
          </el-form-item>
          <el-form-item label="面积(平方米)" prop="areaSize">
            <el-input v-model="form.areaSize" placeholder="面积(平方米)"
              @input="(v) => inputChangeEmptyToNull(v, form, 'areaSize')"></el-input>
          </el-form-item>
          <el-form-item label="发证日期" prop="giveLicenseTime">
            <el-date-picker v-model="form.giveLicenseTime" placeholder="发证日期"></el-date-picker>
          </el-form-item> -->
          <el-form-item label="开工日期" prop="logTime">
            <el-date-picker v-model="form.logTime" placeholder="开工日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="附件" prop="attach">
            <SingleFileupload :setting="fileSetting"></SingleFileupload>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" icon="el-icon-check" @click="save">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import SingleFileupload from "@/components/singleFileupload";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
import {
  numberRulesNoRequired,
  inputChangeEmptyToNull,
} from "@/model/fundFormRules.ts";
export default defineComponent({
  components: { SingleFileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const rules = {
      amount: numberRulesNoRequired,
      areaSize: numberRulesNoRequired,
    };

    const { visible, form, action } = toRefs(props.setting as any);
    const fileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {

    //   }
    // });

    onMounted(() => {
      if (!form.value) form.value = {};
      else {
        fileSetting.fileEmbed = form.value.attach;
      }

      nextTick(() => {
        setTimeout(() => refForm.value.clearValidate(), 1);
      })


    });
    const save = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          // if (fileSetting.fileEmbed) {
          //   form.value.attach = fileSetting.fileEmbed;
          // }
          form.value.attach = fileSetting.fileEmbed;
          emit("change", { action: action.value, data: form.value });
          visible.value = false;
        }
      });
    };

    return {
      rules,
      inputChangeEmptyToNull,
      fileSetting,
      user,
      save,
      refForm,
      visible,
      form,
    };
  },
});
</script>

<style scoped></style>
