<template>
  <el-pagination
    style="padding-left: 5px"
    @current-change="handleCurrentChange"
    @size-change="handleSizeChange"
    v-model:page-size="pageSize"
    :total="pager.count"
    layout="sizes,total, prev, pager, next"
    v-model:currentPage="pageNum"
  ></el-pagination>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    pager: Object,
    default: () => {
      return { pageNum: 1, pageSize: 20, count: 0 };
    },
  },
  emits: ["change"],
  setup(props, context) {
    const { pageSize, pageNum } = toRefs(props.pager);

    return {
      pageSize,
      pageNum,
      handleCurrentChange: (e) => {
        context.emit("change", { pageNum: e });
      },
      handleSizeChange: (e) => {
        context.emit("change", { pageSize: e });
      },
    };
  },
});
</script>

<style scoped>
</style>