export const cascaderValueToString = (value) => {
  if (value == null || !Array.isArray(value)) return "";

  let arr = value.map((p) => {
    if (!Array.isArray(p)) {
      return value[value.length - 1];
    } else {
      return p[p.length - 1];
    }
  });
  arr = [...new Set(arr)];
  return arr.join(",");
};

const arrayConvert = (arr) => {
  let newArr = [];
  arr.forEach((p) => {
    newArr.push(p);
    if (p.children) {
      newArr.push(...arrayConvert(p.children));
    }
  });

  return newArr;
};

export const cascaderStringToValue = (value, option, mulituple = false) => {
  if (value == null || value == "") return [];

  let optionArr = arrayConvert(option);
  let ret = [];
  value.split(",").forEach((v) => {
    let valArr = [];
    let len = v.length / 2;
    for (let i = 1; i < len; i++) {
      let temp = optionArr.find((p) => p.value == v.substring(0, i * 2));
      valArr.push(temp.value);
    }
    valArr.push(v);

    ret.push(valArr);
  });
  return mulituple ? ret : ret[0];
};

export const cascaderStringValueToLabel = (
  value,
  option,
  mulituple = false
) => {
  if (value == null || value == "") return [];

  let optionArr = arrayConvert(option);
  let ret = [];
  value.split(",").forEach((v) => {
    let valArr = [];
    let len = v.length / 2;
    for (let i = 1; i < len; i++) {
      let temp = optionArr.find((p) => p.value == v.substring(0, i * 2));
      //console.log(temp, v.substring(0, i * 2));
      valArr.push(temp.label);
    }

    valArr.push(optionArr.find((p) => p.value == v).label);

    ret.push(valArr);
  });
  return mulituple ? ret : ret[0].join(">");
};

export const cascaderValueToLabel = (value, option, mulituple = false) => {
  let string = cascaderValueToString(value);

  return cascaderStringValueToLabel(string, option, mulituple);
};

export const sumFunc = (list) => {
  const result = list.reduce((prev, curr) => {
    const value = Number(curr);
    if (!isNaN(value)) {
      return prev + value;
    } else {
      return prev;
    }
  }, 0);
  return result.toFixed(2);
};
