import request from "@/utils/request";
import { reactive, onMounted, ref, Ref, toRef } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { copyFrom } from "./common";

export function useQueryProject(filter): Array<any> {
  const isLoading = ref(false);
  const data = reactive({
    list: [],
    count: 0,
  });

  const pager = reactive({
    count: toRef(data, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });

  function query(replaceFilter = null) {
    Object.assign(filter, replaceFilter ?? {});
    isLoading.value = true;
    if (filter.isMemo == "") filter.isMemo = null;
    request.post(`/fundWatch/queryProject`, filter).then((res) => {
      isLoading.value = false;
      data.list = res.list;
      data.count = res.count;
    });
  }

  return [isLoading, data, query, pager];
}

export function useQuery(filter): Array<any> {
  const isLoading = ref(false);
  const data = reactive({
    list: [],
    count: 0,
  });

  const pager = reactive({
    count: toRef(data, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });

  function query(replaceFilter = null) {
    Object.assign(filter, replaceFilter ?? {});
    isLoading.value = true;
    if (filter.isMemo == "") filter.isMemo = null;
    request.post(`/fundWatch/query`, filter).then((res) => {
      isLoading.value = false;
      data.list = res.list;
      data.count = res.count;
    });
  }

  return [isLoading, data, query, pager];
}

export function useFundWatch(
  id: Ref<any> = null,
  data: any = null
): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);
  const empty = {
    id: null,
    year: null,
    project_Fund_Amount: null,
    currentSpend: [],
  };
  const result = data || reactive(copyFrom(empty));

  function find(idValue: number) {
    isLoading.value = true;
    const v = idValue ? idValue : id.value;
    if (v) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      return request
        .get(`/fundWatch/${v}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          if (res != null) {
            copyFrom(result, empty, res);
          } else {
            copyFrom(result, empty);
          }
          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      copyFrom(result, empty);
      return Promise.resolve(result);
    }
  }

  function save(config = null) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id.value) {
      ps = request
        .put(`/fundWatch/${id.value}`, result, config)
        .then((res) => {
          copyFrom(result, res);
          isLoading.value = false;
          ElMessage.success({ message: "资金数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/fundWatch`, result, config)
        .then((res) => {
          copyFrom(result, res);
          isLoading.value = false;
          ElMessage.success({ message: "资金数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  return [isLoading, result, find, save];
}

export const useFundWatchAction = (): Array<any> => {
  const isLoading = ref(false);
  const cancel = ref(null);

  function queryFundTotal(filter) {
    isLoading.value = true;
    return request.post(`/fundWatch/queryFundTotal`, filter).then((res) => {
      isLoading.value = false;
      return res;
    });
  }

  const getfundWatches = (id) => {
    return request.get(`/fundWatch/list/${id}`).then((res) => {
      return res;
    });
  };

  const projectFind = (id) => {
    isLoading.value = true;
    return request
      .get(`/fundWatch/projectFind/${id}`)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch((ex) => {
        isLoading.value = false;
        throw ex;
      });
  };
  const projectUpdate = (id, item) => {
    isLoading.value = true;
    return request
      .put(`/fundWatch/projectUpdate/${id}`, item)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch((error) => {
        isLoading.value = false;
        ElMessage.error({
          message:
            "项目进度信息提交失败！各处金额、面积仅需填写数字，无需填写 元/万元/米/平方米 等相关文字，请检查输入内容后再次尝试提交！",
          type: "error",
          duration: 5000,
        });
        throw error;
      });
  };

  const projectUpdateTmp = (id, item) => {
    isLoading.value = true;

    return request
      .put(
        `/fundWatch/ProjectUpdateTmp/${id}`,
        `tmpData=${JSON.stringify(item)}`
      )
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch((error) => {
        isLoading.value = false;
        ElMessage.error({
          message: "保存失败！请检查输入内容后重新尝试，取消则数据不会被保存！",
          type: "error",
        });
        throw error;
      });
  };
  const projectDispath = (id, userid) => {
    isLoading.value = true;
    return request
      .put(`/fundWatch/ProjectDispatch/${id}/${userid}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };
  const projectFinishCheck = (id, dto) => {
    isLoading.value = true;
    return request
      .put(`/fundWatch/projectFinishCheck/${id}`, dto)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const dispath = (id, userid) => {
    isLoading.value = true;
    return request
      .put(`/fundWatch/Dispatch/${id}/${userid}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const memoUpdate = (id, memo) => {
    isLoading.value = true;
    return request({
      headers: {
        "Content-Type": "application/json",
      },
      method: "put",
      url: `/fundWatch/MemoUpdate/${id}`,
      data: JSON.stringify(memo),
    })
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const logFundWatchTmpSaveErr = (item) => {
    return request.post(
      `/fundWatch/logFundWatchTmpSaveErr/${item.id}`,
      `memo=${JSON.stringify(item)}`
    );
  };

  return [
    isLoading,
    dispath,
    memoUpdate,
    projectDispath,
    projectUpdate,
    projectFinishCheck,
    getfundWatches,
    projectFind,
    queryFundTotal,
    projectUpdateTmp,
    logFundWatchTmpSaveErr,
  ];
};
