
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import {
  fundWatchProjectDoStatusDic,
  fundWatchSourceDic,
} from "@/network/lookUp.ts";
import { parseTime } from "@/utils/index.js";
import FundRunSubmit from "./fundRunSubmit.vue";
import BiddingManage from "./biddingManage.vue";
import ContractMange from "./contractMange.vue";
import FirstCheckMange from "./firstCheckMange.vue";
import ProjectStartMange from "./projectStartMange.vue";
// import { reactiveShowSetting } from "@/types/showSetting.ts";
import { useFundWatchAction } from "@/network/fundWatch";
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from "vue";

export default defineComponent({
  components: {
    FundRunSubmit,
    BiddingManage,
    ContractMange,
    FirstCheckMange,
    ProjectStartMange,
    FileuploadListV1
  },
  emits: ["change"],
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const activeName = ref(props.setting.active_Name || "project");
    const [isProcessing, , , , projectUpdate, , getfundWatches, projectFind] =
      useFundWatchAction();
    // const showSetting = reactiveShowSetting();
    const biddingManageSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const contractMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const projectStartMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const firstCheckSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const activeNames = ref(["1", "2", "3", "4"]);
    const attachmentType = ref(null);
    const form = reactive({
      id: null,
      biddingInfos: [],
      contractInfos: [],
      projectStartInfos: [],
      firstCheckInfos: [],
      unionCheckInfos: [],
      attachList: []
    });

    const fundWatches = ref([]);

    const { visible, id } = toRefs(props.setting as any);

    const manageShow = (setting, item) => {
      setting.action = item == null ? "add" : "update";
      setting.visible = true;
      setting.form = item;
    };

    const removeFromList = (list, item) => {
      const index = list.findIndex((p) => item == p);
      if (index >= -1) {
        list.splice(index, 1);
      }
    };

    const onListManged = (list, { action, data }) => {
      //console.log(action, data);
      if (action == "add") {
        data.createTime = new Date();
        // data.createTime = parseTime(new Date(), "{yyyy}-{mm}-{dd}");
        // console.log(data,parseTime(new Date(), "{yyyy}-{mm}-{dd}"))
        list.push(data);
      }
    };

    onMounted(() => {
      isLoading.value = true;
      activeName.value = props.setting.active_Name || "project";
      projectFind(props.setting.id).then((p) => {
        Object.assign(form, p);
        isLoading.value = false;
      });

      getfundWatches(props.setting.id).then((p) => {
        fundWatches.value = p;
      });
    });

    const save = () => {
      projectUpdate(id.value, form).then((o) => {
        emit("change");
      });
    };

    return {
      fundWatchProjectDoStatusDic,
      fundWatchSourceDic,
      isLoading,
      activeName,
      parseTime,
      isProcessing,
      manageShow,
      removeFromList,
      save,
      biddingManageSetting,
      contractMangeSetting,
      projectStartMangeSetting,
      firstCheckSetting,
      onListManged,
      // showSetting,
      activeNames,
      attachmentType,
      visible,
      confirm,
      form,
      fundWatches,
    };
  },
});
