
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { parseTime } from "@/utils/index.js";
import { delayExec } from "@/utils/util.js";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import FundRunSubmit from "./fundRunSubmit.vue";
import BiddingManage from "./biddingManage.vue";
import ContractMange from "./contractMange.vue";
import FirstCheckMange from "./firstCheckMange.vue";
import UnionCheckMange from "./unionCheckMange.vue";
import ProjectStartMange from "./projectStartMange.vue";

import {
  numberRules,
  numberRulesNoRequired,
  inputChangeEmptyToNull,
} from "@/model/fundFormRules";
import { useFundWatchAction } from "@/network/fundWatch";
import {
  fundWatchProjectDoStatusDic,
  fundWatchSourceDic,
} from "@/network/lookUp.ts";

import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from "vue";
import { right } from "@popperjs/core";
import { promises } from "dns";

export default defineComponent({
  components: {
    FileuploadListV1,
    FundRunSubmit,
    BiddingManage,
    ContractMange,
    FirstCheckMange,
    ProjectStartMange,
    SelectUnit,
    UnionCheckMange,
  },
  emits: ["change"],
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const user: any = inject("user");
    const refForm2 = ref(null);
    const refForm3 = ref(null);
    const refForm4 = ref(null);
    const rules = ref({
      setupDoc_Title: {
        required: true,
        message: "计划书批复名称不能为空!",
        trigger: "blur",
      },
      setupDoc: {
        required: true,
        message: "计划书批复文号不能为空!",
        trigger: "blur",
      },
      proposalDoc: {
        required: true,
        message: "技术方案批复文号不能为空!",
        trigger: "blur",
      },
      proposalDoc_Title: {
        required: true,
        message: "技术方案方案名称不能为空!",
        trigger: "blur",
      },
      project_DoStatus: {
        required: true,
        message: "进展状态不能为空!",
        trigger: "change",
      },
      questionDetail: {
        required: true,
        message: "进展描述不能为空!",
        trigger: "blur",
      },
      project_Fund_Source: {
        required: true,
        message: "资金来源不能为空!",
        trigger: "blur",
      },
      project_DoUnit: {
        required: true,
        message: "施工单位不能为空!",
        trigger: "blur",
      },
      project_DesignUnit: {
        required: true,
        message: "设计单位不能为空!",
        trigger: "blur",
      },
      project_WatchUnit: {
        required: true,
        message: "监理单位不能为空!",
        trigger: "blur",
      },
      project_ManageUnit: {
        required: true,
        message: "管理单位不能为空!",
        trigger: "blur",
      },
      project_Fund_Amount: numberRules,
    });
    const isLoading = ref(true);
    const activeName = ref("project");
    const [
      isProcessing,
      ,
      ,
      ,
      projectUpdate,
      ,
      getfundWatches,
      projectFind,
      ,
      projectUpdateTmp,
      logFundWatchTmpSaveErr,
    ] = useFundWatchAction();

    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });

    const biddingManageSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const contractMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const projectStartMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const firstCheckSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const unionCheckSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const activeNames = ref(["1", "2", "3", "4"]);
    const attachmentType = ref(null);
    const form = reactive({
      id: null,
      fundWatches: null,
      heritageUnit_Name: null,
      biddingInfos: [],
      contractInfos: [],
      projectStartInfos: [],
      firstCheckInfos: [],
      unionCheckInfos: [],
      attachList: []
    });

    const fundWatches = ref([]);

    const { visible, id } = toRefs(props.setting as any);
    const fundRunSubmitRef = ref([]);
    const setFundSubmitInRefs = (el, id) => {
      if (el != null && !fundRunSubmitRef.value.some((p) => p == el)) {
        //console.log(el, "bbb");
        fundRunSubmitRef.value.push(el);
      }
    };
    const tempSave = () => {
      // console.log(fundWatches.value);
      // const d = Object.assign({ fundWatches: fundWatches.value }, form);
      // localStorage.setItem("_fundSubmit_tmp_" + form.id, JSON.stringify(d));
      // ElMessage.success({ message: "数据已保存", type: "success" });

      const data = Object.assign(
        {
          _updateTime: new Date(),
          _updateUser: user.name,
          fundWatches: fundWatches.value,
          tmpData: null,
        },
        form
      );
      delete data.tmpData;

      if (!data.id) {
        ElMessage.error({
          message: "暂存失败！数据异常，请刷新页面后重新填报！",
          type: "error",
          duration: 5000,
        });
        return false;
      }
      projectUpdateTmp(id.value, data).then((p) => {
        if (localStorage.getItem("_fundSubmit_tmp_" + data.id)) {
          localStorage.setItem("_fundSubmit_tmp_" + data.id + "_Expire", "Y");
        }
      });
    };
    const manageShow = (setting, item, action = item == null ? "add" : "update") => {
      setting.action = action;
      setting.form = item;
      setting.visible = true;
    };

    const removeFromList = (list, item) => {
      const index = list.findIndex((p) => item == p);
      if (index >= -1) {
        list.splice(index, 1);
      }
    };

    const onListManged = (list, { action, data }) => {
      //console.log(list, action, data);
      if (action == "add") {
        data.createTime = new Date();
        // data.createTime = parseTime(new Date(), "{yyyy}-{mm}-{dd}");
        // console.log(data,parseTime(new Date(), "{yyyy}-{mm}-{dd}"))
        list.push(data);
      }
    };

    const checkSetFundwatches = (data, isCheckSetFundwatches) => {
      return isCheckSetFundwatches
        ? delayExec(10).then(() => {
          const watches = data.fundWatches;
          //console.log(watches, "111");
          const errors = watches.filter((p) => p.project_Id != data.id);
          if (errors.length > 0) {
            logFundWatchTmpSaveErr(form);
            return getfundWatches(data.id).then((rights) => {
              if (errors.length == 1 && rights.length == 1) {
                rights[0].fundFileCode = errors[0].fundFileCode;
                rights[0].fundGetDate = errors[0].fundGetDate;
                rights[0].funGetAmount = errors[0].funGetAmount;
                rights[0].project_FundDo_Status =
                  errors[0].project_FundDo_Status;
                rights[0].currentSpend = errors[0].currentSpend;
                rights[0].fundDoSlowReason = errors[0].fundDoSlowReason;
              }
              data.fundWatches = rights;
              return data.fundWatches;
            });
          } else {
            return Promise.resolve(watches);
          }
        })
        : getfundWatches(data.id);
    };

    onMounted(async () => {
      isLoading.value = true;
      activeName.value = props.setting.active_Name || "project";

      try {
        //服务器数据
        const projectData = await projectFind(props.setting.id);
        //本地缓存
        const tempData = localStorage.getItem(
          "_fundSubmit_tmp_" + props.setting.id
        );
        //本地缓存过期
        const tempDataCheckExpire = localStorage.getItem(
          "_fundSubmit_tmp_" + props.setting.id + "_Expire"
        );
        //是否缓存子数据校验
        let isCheckSetFundwatches = false;
        if (tempData != null && tempDataCheckExpire == null) {
          Object.assign(form, JSON.parse(tempData));
          isCheckSetFundwatches = true;
        }
        //云端缓存
        else if (projectData.tmpData != null) {
          Object.assign(form, JSON.parse(projectData.tmpData));
          isCheckSetFundwatches = true;
        }
        //无缓存
        else {
          Object.assign(form, projectData);
          isCheckSetFundwatches = false;
        }
        if (form.fundWatches == null || form.fundWatches.length == 0) {
          form.fundWatches = await getfundWatches(form.id);
        }
        //加载子数据
        fundWatches.value = await checkSetFundwatches(
          form,
          isCheckSetFundwatches
        );
        isLoading.value = false;

        selectUnitSetting.heritage_Name = form.heritageUnit_Name;

        if (form.attachList == null) form.attachList = [];
      } catch (p) {
        //console.log(p)
        visible.value = false;
        ElMessage.error({
          message: "数据加载失败，请尝试重新加载！",
          type: "error",
        });
      }
    });

    const save = () => {

      if (activeName.value == "project") {

        Promise.all([refForm2.value?.validate(), refForm3.value?.validate(), refForm4.value?.validate()]).then(() => {
          projectUpdate(id.value, form).then((o) => {
            if (localStorage.getItem("_fundSubmit_tmp_" + form.id)) {
              localStorage.setItem(
                "_fundSubmit_tmp_" + form.id + "_Expire",
                "Y"
              );
            }
            // localStorage.removeItem("_fundSubmit_tmp_" + form.id);

            ElMessageBox.confirm(
              "数据已提交成功！是否关闭当前填报窗口？",
              "操作提示",
              { type: "success" }
            )
              .then(() => {
                visible.value = false;
                emit("change");
              })
              .catch(() => {

                form.biddingInfos = o.biddingInfos;
                form.contractInfos = o.contractInfos;
                form.projectStartInfos = o.projectStartInfos;
                form.firstCheckInfos = o.firstCheckInfos;
                form.attachList = o.attachList;
                form.unionCheckInfos = o.unionCheckInfos;
                emit("change");
              });
          });
        })
          .catch((err) => {
            ElMessage.error({
              message:
                "提交失败！各处金额、面积仅需填写数字，无需填写 元/万元/米/平方米 等相关文字，请检查输入表单提示红框处内容后再次尝试提交！",
              type: "error",
              duration: 5000,
            });
          });

      }
      else {
        const listSave = fundRunSubmitRef.value.map((p) => p.save());
        Promise.all(listSave).then(p => {

          ElMessageBox.confirm(
            "数据已提交成功！是否关闭当前填报窗口？",
            "操作提示",
            { type: "success" }
          )
            .then(() => {
              visible.value = false;
              emit("change");
            })

        }).catch((e) => {
          ElMessage.error({
            message:
              "资金支出信息提交失败！各处金额、面积仅需填写数字，无需填写 元/万元/米/平方米 等相关文字，请检查输入表单提示红框处内容后再次尝试提交！",
            type: "error",
            duration: 5000,
          });
        });

      }




    };

    const selectUnitChange = (item) => {
      form.heritageUnit_Name = item.heritage_Name;
    };

    return {
      selectUnitSetting,
      selectUnitChange,
      refForm2,
      refForm3,
      refForm4,
      rules,
      inputChangeEmptyToNull,
      isLoading,
      tempSave,
      activeName,
      parseTime,
      isProcessing,
      manageShow,
      removeFromList,
      save,
      biddingManageSetting,
      contractMangeSetting,
      projectStartMangeSetting,
      firstCheckSetting,
      unionCheckSetting,
      onListManged,

      activeNames,
      attachmentType,
      visible,
      confirm,
      form,
      fundWatches,
      fundRunSubmitRef,
      setFundSubmitInRefs,
      fundWatchProjectDoStatusDic,
      fundWatchSourceDic,
    };
  },
});
