<template>
  <el-collapse v-model="activeNames">
    <el-collapse-item name="1"
      :title="`【${form.year}】${form.project_Fund_Source} 下达(${form.project_Fund_Amount}万元)支出情况`">
      <el-form :model="form" label-width="200px" ref="refForm" :rules="rules" :disabled="disabled">
        <el-row>
          <el-col :span="8">
            <el-form-item label="资金来源">
              <el-select v-model="form.project_Fund_Source" placeholder clearable :disabled="true">
                <el-option v-for="item in fundWatchSourceDic" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下达年份">{{ form.year }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下达金额（万元）">{{
    form.project_Fund_Amount
  }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="财政文号" prop="fundFileCode">
              <el-input v-model="form.fundFileCode" placeholder="财政文号" style="width: 220px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="资金实际到位时间" prop="fundGetDate">
              <el-date-picker v-model="form.fundGetDate" placeholder="到位时间"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到位金额（万元）" prop="funGetAmount">
              <el-input v-model="form.funGetAmount" placeholder="到位金额" style="width: 220px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="资金执行状态" prop="project_FundDo_Status">
          <el-select v-model="form.project_FundDo_Status" placeholder="状态" clearable>
            <el-option v-for="item in fundWatchDoStatusDic" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="已支出情况" prop="currentSpend">
          <p v-if="!disabled">
            <el-button type="primary" icon="el-icon-plus" @click="add">添 加</el-button>
          </p>
          <div style="overflow: auto">
            <el-table :data="form.currentSpend" :show-summary="true" :span-method="arraySpanMethod"
              :summary-method="getSummaries" style="min-width: 1280px">
              <el-table-column label="资金下达年份" prop="year" width="120"></el-table-column>
              <el-table-column label="下达金额（万元）" prop="project_Fund_Amount" width="120"></el-table-column>

              <el-table-column label="发生日期" width="240">
                <template v-slot="{ row: item, $index }">
                  <template v-if="item.isEdit">
                    <el-form-item :prop="`currentSpend.${$index}.date`" :rules="[
    {
      required: true,
      message: '请选择日期！',
    },
  ]">
                      <el-date-picker v-model="item.date" placeholder="发生日期"></el-date-picker>
                    </el-form-item>
                  </template>
                  <template v-else>{{
    parseTime(item.date, "{yyyy}年{mm}月{dd}日")
  }}</template>
                </template>
              </el-table-column>
              <el-table-column label="支出内容">
                <template v-slot="{ row: item }">
                  <template v-if="item.isEdit">
                    <el-input v-model="item.memo" placeholder="支出内容"></el-input>
                  </template>
                  <template v-else>{{ item.memo }}</template>
                </template>
              </el-table-column>
              <el-table-column label="支出金额（万元）" prop="money">
                <template v-slot="{ row: item, $index }">
                  <template v-if="item.isEdit">
                    <el-form-item :prop="`currentSpend.${$index}.money`" required :rules="numberRulesNoRequired">
                      <el-input v-model="item.money" placeholder="支出金额（万元）"></el-input>
                    </el-form-item>
                  </template>
                  <template v-else>{{ item.money }}</template>
                </template>
              </el-table-column>
              <el-table-column label="附件" fixed="right">
                <template v-slot="{ row: item }">
                  <div v-for="file in item.attachments" :key="file.filePath">
                    <a :href="file.filePath" :download="file.fileName">[{{ file.type }}]{{ file.fileName }}</a>
                    &nbsp;
                    <el-popconfirm title="确认删除吗！？" @confirm="() => {
    item.attachments.splice(
      item.attachments.indexOf(file),
      1
    );
  }
    ">
                      <template #reference>
                        <el-button type="text" icon="el-icon-close" style="color: red"></el-button>
                      </template>
                    </el-popconfirm>
                  </div>
                  <div v-if="item.isEdit">
                    <el-space>
                      <el-select placeholder="附件类型" v-model="item.attachmentType" clearable>
                        <el-option label="合同" value="合同"></el-option>
                        <el-option label="凭证" value="凭证"></el-option>
                        <el-option label="发票" value="发票"></el-option>
                        <el-option label="竣工报告" value="竣工报告"></el-option>
                        <el-option label="财务验收报告" value="财务验收报告"></el-option>
                        <el-option label="其他" value="其他"></el-option>
                      </el-select>

                      <Fileupload :setting="{
    simple: true,
    limit: 1,
    accpet: 'application/pdf',
    fileList: [],
    showTip: false,
  }" @upload="({ url, name }) => {
    item.attachments.push({
      type: item.attachmentType,
      filePath: url,
      fileName: name,
    });
  }
    ">
                        <el-button type="primary" @click="(ev) => {
    if (isEmpty(item.attachmentType)) {
      ElMessage.error({
        message: '请先选择上传附件类型！',
        type: 'error',
      });
      return ev.stopPropagation();
    }
  }
    ">上传</el-button>
                      </Fileupload>
                    </el-space>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="50" v-if="!disabled" fixed="right">
                <template #default="{ row: item }">
                  <el-popconfirm title="确认删除吗！？" @confirm="
    form.currentSpend.splice(
      form.currentSpend.indexOf(item),
      1
    )
    ">
                    <template #reference>
                      <el-button size="large" type="text" icon="el-icon-delete" style="color: red"></el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="执行进度说明及整改计划" prop="fundDoSlowReason">
          <el-input v-model="form.fundDoSlowReason" type="textarea" placeholder="执行进度说明及整改计划" rows="5"></el-input>
        </el-form-item>
        <!-- <el-form-item label="结转资金（项目未完工未支付资金）万元" prop="project_Fund_Amount_UnFinish_NoPay">
          <el-input v-model="form.project_Fund_Amount_UnFinish_NoPay" style="width:220px"></el-input>
        </el-form-item>
        <el-form-item label="被同级财政部门收回资金（万元）" prop="project_Fund_Amount_Return">
          <el-input v-model="form.project_Fund_Amount_Return" style="width:220px"></el-input>
        </el-form-item>
        <el-form-item label="结余资金（项目完工后未支付）" prop="project_Fund_Amount_Finish_NoPay">
          <el-input v-model="form.project_Fund_Amount_Finish_NoPay" style="width:220px"></el-input>
        </el-form-item>
        <el-form-item label="目前未支出资金存放单位（市财政或项目单位）" prop="project_Fund_NoUsed_SaveUnit">
          <el-input v-model="form.project_Fund_NoUsed_SaveUnit"></el-input>
        </el-form-item>
        <el-form-item label="合同金额（万元）" prop="project_Contract_Amount">
          <el-input v-model="form.project_Contract_Amount" style="width:220px"></el-input>
        </el-form-item>-->
        <!-- <el-form-item label v-if="!disabled">
          <el-button type="success" @click="save">提交</el-button>
        </el-form-item> -->
      </el-form>
    </el-collapse-item>
    <!-- <el-collapse-item name="2" title="累计到位与支出情况">
          <el-table :data="form.amountSend">
            <el-table-column label="发生日期"></el-table-column>
            <el-table-column label="支出内容"></el-table-column>
            <el-table-column label="到位金额（万元）"></el-table-column>
            <el-table-column label="支出金额（万元）"></el-table-column>
            <el-table-column label="余额（万元）"></el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="3" title="所有附件列表">
          <el-table :data="form.allAttachments">
            <el-table-column label="文件类型"></el-table-column>
            <el-table-column label="文件名"></el-table-column>
            <el-table-column label="上传日期"></el-table-column>
            <el-table-column label="操作"></el-table-column>
          </el-table>
    </el-collapse-item>-->
  </el-collapse>
</template>

<script lang="ts">
import { ElMessage } from "element-plus";
import { isEmpty } from "@/utils/validate.js";
import { parseTime } from "@/utils/index.js";
import { sumFunc } from "@/utils/dataFormat.js";
import { defineComponent, toRefs, ref, reactive, computed, watch, h } from "vue";
import { useFundWatch } from "@/network/fundWatch";
import { fundWatchDoStatusDic, fundWatchSourceDic } from "@/network/lookUp.ts";
import { ElInput } from "element-plus";
import Fileupload from "@/components/fileupload";
import { numberRulesNoRequired, numberRules } from "@/model/fundFormRules";

export default defineComponent({
  props: {
    id: { type: Number },
    disabled: { type: Boolean },
    modelValue: { type: Object },
  },
  emits: ["changed"],
  components: {
    Fileupload,
  },
  setup(props, { emit }) {
    const refForm = ref(null);
    const activeNames = ref(["1", "2", "3"]);
    const attachmentType = ref(null);

    const [isLoading, form, , saveData] = useFundWatch(
      ref(props.id),
      props.modelValue
    );

    //console.log(props.modelValue)

    // const form = reactive({
    //   id,
    //   year,
    //   project_Fund_Amount,
    //   currentSpend: [
    //   ],
    // });
    if (!form.currentSpend) form.currentSpend = [];
    if (form.project_Fund_Amount_Used && form.currentSpend.length == 0) {
      form.currentSpend.push({
        year: form.year,
        project_Fund_Amount: form.project_Fund_Amount,
        isEdit: true,
        money: form.project_Fund_Amount_Used,
        memo: "系统导入-已支出",
        attachments: [],
      });
    }

    const rules = {
      project_Fund_Amount_UnFinish_NoPay: numberRulesNoRequired,
      project_Fund_Amount_Return: numberRulesNoRequired,
      project_Fund_Amount_Finish_NoPay: numberRulesNoRequired,
      project_Contract_Amount: numberRulesNoRequired,
      funGetAmount: numberRules,
      currentSpend: [
        {
          message: "支出总金额不得大于下达金额！",
          trigger: "change",
          validator: (rule, value, callback) => {
            if (value && value.length > 0) {
              try {
                const sum = sumFunc(value.map((p) => p.money));
                if (sum > form.project_Fund_Amount) {
                  return callback(Error(rule.message));
                }
              } catch {
                return callback(Error("支出金额输入错误！"));
              }
            }
            callback();
          },
        },
      ],
      fundFileCode: [
        {
          required: true,
          message: "财政文号不能为空!",
          trigger: "blur",
        },
      ],
      fundGetDate: [
        {
          required: true,
          message: "资金实际到位时间不能为空!",
          trigger: "blur",
        },
      ],
      project_FundDo_Status: [
        {
          required: true,
          message: "资金执行状态不能为空!",
          trigger: "change",
        },
      ],
      fundDoSlowReason: [
        {
          required: true,
          message: "执行进度说明及整改计划!",
          trigger: "blur",
        },
      ],
    };

    const save = () => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((vaild) => {
          if (vaild) {
            saveData()
              .then((p) => {
                emit("changed", p);
                resolve(p);
              })
              .catch((p) => {
                reject("资金信息填报错误！");
              });
          } else {
            reject("资金信息填报错误！");
          }
        });
      });
    };
    const confirm = () => {
      //console.log(visible);
    };
    const arraySpanMethod = ({ row, column, rowIndex, columnIndex }) => {
      if (columnIndex === 0 || columnIndex === 1) {
        if (rowIndex === 0) {
          return [form.currentSpend.length, 1];
        } else {
          return [0, 0];
        }
      } else {
        return [1, 1];
      }
    };

    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums: any[] = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 1) {
          sums[index] = form["project_Fund_Amount"];
          return;
        }
        if (index === 2) {
          const span = h("span", "应付未付金额")

          const input = h(ElInput, {
            modelValue: form.project_Fund_Amount_Finish_NoPay, style: { width: '200px' }, ["onUpdate:modelValue"]: (v) => {
              form.project_Fund_Amount_Finish_NoPay = v;
            }
          });

          sums[index] = h("div",[span,input]);
          return;
        }
        else if (index === 3) {
    
          return;
        }
        else if (index != 4) {
          sums[index] = "";
          return;
        } else {
          try {
            const sum = sumFunc(data.map((item) => item[column.property]));
            sums[index] =
              sum > form.project_Fund_Amount
                ? "数据异常，支出总金额不得大于下达金额！"
                : sum.toString();
          } catch (err) {
            console.log(err);
            sums[index] = "数据异常";
          }
        }
      });

      return sums;
    };

    const add = () => {
      form.currentSpend.push({
        year: form.year,
        project_Fund_Amount: form.project_Fund_Amount,
        isEdit: true,
        attachments: [],
      });
    };

    return {
      ElMessage,
      isEmpty,
      numberRulesNoRequired,
      rules,
      refForm,
      parseTime,
      fundWatchSourceDic,
      fundWatchDoStatusDic,
      isLoading,
      getSummaries,
      arraySpanMethod,
      activeNames,
      attachmentType,
      confirm,
      form,
      add,
      save,
    };
  },
});
</script>

<style scoped></style>
