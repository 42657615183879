
// import SingleFileupload from "@/components/singleFileupload";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
export default defineComponent({
  //components: { SingleFileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, form, action } = toRefs(props.setting as any);
    const expertAdviseFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const amountReportFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const watchReportFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const elseFileFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });

    //console.log(form.value, expertAdviseFileSetting.fileEmbed);
    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {

    //   }
    // });

    onMounted(() => {
      if (!form.value) form.value = {};
      else {
        expertAdviseFileSetting.fileEmbed = form.value.expertAdvise;
        amountReportFileSetting.fileEmbed = form.value.amountReport;
        watchReportFileSetting.fileEmbed = form.value.watchReport;
        elseFileFileSetting.fileEmbed = form.value.elseFile;
      }
      nextTick(() => {
        setTimeout(() => refForm.value.clearValidate(), 1);
      })


    });
    const save = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          form.value.expertAdvise = expertAdviseFileSetting.fileEmbed;
          form.value.amountReport = amountReportFileSetting.fileEmbed;
          form.value.watchReport = watchReportFileSetting.fileEmbed;
          form.value.elseFile = elseFileFileSetting.fileEmbed;

          emit("change", { action: action.value, data: form.value });
          visible.value = false;
        }
      });
    };

    return {
      expertAdviseFileSetting,
      amountReportFileSetting,
      watchReportFileSetting,
      elseFileFileSetting,
      user,
      save,
      refForm,
      visible,
      form,
    };
  },
});
