<template>
  <el-dialog v-model="visible" title="文物保护项目进展情况登记表" width="90%">
    <!-- <DocContent :setting="showSetting" v-if="showSetting.visible"></DocContent> -->
    <el-card v-loading="isLoading">
      <el-tabs tab-position="left" v-model="activeName">

        <el-tab-pane label="项目进度情况" name="project" v-if="setting.active_Name == 'project'">
          <el-collapse v-model="activeNames">
            <el-collapse-item name="1" title="基本信息">
              <el-form :model="form" label-width="200px" :rules="rules" :disabled="true">
                <!-- <el-form-item label="时期">{{ form.age }}</el-form-item> -->
                <el-form-item label="市级">{{ form.city_Name }}</el-form-item>
                <el-form-item label="区县">{{
                  form.country_Name
                }}</el-form-item>

                <!-- {{
                  form.heritageUnit_Name
                }} -->

                <el-form-item label="文保单位">
                  <SelectUnit :setting="selectUnitSetting" @change="selectUnitChange">
                  </SelectUnit>
                </el-form-item>

                <el-form-item label="可移动/不可移动">
                  <el-radio-group v-model="form.project_Type">
                    <el-radio-button label="可移动"></el-radio-button>
                    <el-radio-button label="不可移动"></el-radio-button>

                    <!-- <el-radio-button label="监理"></el-radio-button> -->
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-collapse-item>
            <!-- <el-collapse-item name="2" title="计划书">
              <el-form :model="form" label-width="200px" :rules="rules" ref="refForm2">
                <el-form-item label="计划书批复名称" prop="setupDoc_Title">
                  <el-input v-model="form.setupDoc_Title"></el-input>
                </el-form-item>
                <el-form-item label="计划书日期">
                  <el-date-picker v-model="form.setupDoc_Date"></el-date-picker>
                </el-form-item>
                <el-form-item label="业主单位名称">
                  <el-input v-model="form.project_Unit_Name" placeholder="请填写"></el-input>
                </el-form-item>
                <el-form-item label="批复文号" prop="setupDoc">
                  <el-input v-model="form.setupDoc"></el-input>
                </el-form-item>
              </el-form>
            </el-collapse-item>
            <el-collapse-item name="3" title="技术方案情况">
              <el-form :model="form" label-width="200px" :rules="rules" ref="refForm3">
                <el-form-item label="技术方案进展">
                  <el-select v-model="form.proposalDoc_Status">
                    <el-option label="未编制" value="未编制"></el-option>
                    <el-option label="技术方案编制中" value="技术方案编制中"></el-option>
                    <el-option label="方案审批中" value="方案审批中"></el-option>
                    <el-option label="获得方案批复" value="获得方案批复"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="技术方案名称" prop="proposalDoc_Title">
                  <el-input v-model="form.proposalDoc_Title"></el-input>
                </el-form-item>
                <el-form-item label="方案编制单位">
                  <el-input v-model="form.proposalDoc_DesignUnit"></el-input>
                </el-form-item>
                <el-form-item label="方案编制日期">
                  <el-date-picker v-model="form.proposalDoc_WriteDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="方案批复日期">
                  <el-date-picker v-model="form.proposalDoc_AccessDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="方案批复文号" prop="proposalDoc">
                  <el-input v-model="form.proposalDoc"></el-input>
                </el-form-item>
              </el-form>
            </el-collapse-item> -->
            <el-collapse-item name="4" title="工程实施情况">
              <el-form :model="form" label-width="200px" ref="refForm4" :rules="rules" :disabled="true">
                <!-- <el-form-item label="项目名称">
                  <el-input v-model="form.project_Name" :disabled="true"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="项目编号">
                  <el-input v-model="form.project_Code"></el-input>
                </el-form-item>

                <el-form-item label="文物类别">
                  <el-input v-model="form.heritage_Type"></el-input>
                </el-form-item>
                <el-form-item label="项目类别">
                  <el-input v-model="form.project_Type"></el-input>
                </el-form-item> -->
                <el-form-item label="项目负责人">
                  <el-input v-model="form.project_Manager"></el-input>
                </el-form-item>
                <!-- <el-form-item label="职务">
                  <el-input v-model="form.project_Manager_Job"></el-input>
                </el-form-item> -->
                <el-form-item label="联系电话">
                  <el-input v-model="form.project_Manager_Phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="form.project_Manager_Email"></el-input>
                </el-form-item>
                <!-- <el-form-item label="财务负责人">
                  <el-input v-model="form.project_FundManager"></el-input>
                </el-form-item>
                <el-form-item label="职务">
                  <el-input v-model="form.project_FundManager_Job"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input v-model="form.project_FundManager_Phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="form.project_FundManager_Email"></el-input>
                </el-form-item> -->
                <el-form-item label="进展状态" prop="project_DoStatus">
                  <el-select v-model="form.project_DoStatus">
                    <el-option :key="item" :label="item" :value="item"
                      v-for="item in fundWatchProjectDoStatusDic"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="进展描述" prop="questionDetail">
                  <el-input type="textarea" v-model="form.questionDetail"></el-input>
                </el-form-item>

                <!-- <el-form-item label="工程经费(万元)" prop="project_Fund_Amount">
                  <el-input v-model="form.project_Fund_Amount" @input="(v) =>
    inputChangeEmptyToNull(v, form, 'project_Fund_Amount')
    " style="width: 220px"></el-input>
                </el-form-item> -->
                <el-form-item label="经费来源" prop="project_Fund_Source">
                  <el-select v-model="form.project_Fund_Source" :disabled="true">
                    <el-option :label="item" :value="item" v-for="item in fundWatchSourceDic" :key="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="施工单位" prop="project_DoUnit">
                  <el-input v-model="form.project_DoUnit"></el-input>
                </el-form-item>
                <el-form-item label="设计单位" prop="project_DesignUnit">
                  <el-input v-model="form.project_DesignUnit"></el-input>
                </el-form-item>
                <el-form-item label="监理单位" prop="project_WatchUnit">
                  <el-input v-model="form.project_WatchUnit"></el-input>
                </el-form-item>
                <el-form-item label="管理单位" prop="project_ManageUnit">
                  <el-input v-model="form.project_ManageUnit"></el-input>
                </el-form-item>
                <el-form-item label="开工日期">
                  <el-date-picker v-model="form.project_StartDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="完工日期">
                  <el-date-picker v-model="form.project_EndDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="相关附件(图片、文档、资料)" prop="attachList">
                  <FileuploadListV1 v-model:files="form.attachList"
                    accpet=".zip,.rar,.pdf,.wps,.ofd,.doc,.docx,.jpg,.png,.gif" urlKey="filePath" nameKey="fileName">
                  </FileuploadListV1>
                </el-form-item>
                <el-tabs>
                  <el-tab-pane label="招标信息">
                    <!-- <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(biddingManageSetting, null)">添加招标信息</el-button> -->
                    <el-table :data="form.biddingInfos">
                      <!-- <el-table-column label="中标单位" prop="bidedUnitName"></el-table-column> -->
                      <el-table-column label="是否招标" prop="biddingType"></el-table-column>
                      <!-- <el-table-column label="招标方式" prop="biddingMethod"></el-table-column>
                      <el-table-column label="中标日期" prop="bidedDate">
                        <template #default="{ row: item }">{{
    parseTime(item.bidedDate, "{yyyy}-{mm}-{dd}")
  }}</template>
</el-table-column> -->
                      <el-table-column label="中标金额（万元）" prop="bidedAmount"></el-table-column>
                      <!-- <el-table-column label="中标通知书编号" prop="bidedFileNo"></el-table-column> -->
                      <!-- <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <a :download="item.attach.fileName" v-if="item.attach" :href="item.attach.filePath"
                            target="_blank">{{ item.attach.fileName }}</a>
                        </template>
                      </el-table-column> -->
                      <el-table-column label="提交日期" prop="createTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                        }}</template>
                      </el-table-column>
             
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="工程合同信息">
                    <!-- <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(contractMangeSetting, null)">添加工程合同信息</el-button> -->
                    <el-table :data="form.contractInfos">
                      <el-table-column label="合同类别" prop="contractType"></el-table-column>
                      <!-- <el-table-column label="合同登记编号" prop="contractNo"></el-table-column> -->
                      <el-table-column label="合同金额（万元）" prop="contractAmount"></el-table-column>
                      <el-table-column label="中标单位名称" prop="contractUnit"></el-table-column>
                      <!-- <el-table-column label="发包单位名称" prop="contractUnit"></el-table-column>
                      <el-table-column label="承包单位名称" prop="contractor"></el-table-column> -->
                      <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <a v-if="item.attach" :download="item.attach.fileName" :href="item.attach.filePath"
                            target="_blank">{{ item.attach.fileName }}</a>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期" prop="createTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                        }}</template>
                      </el-table-column>

                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="开工信息">
                    <!-- <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(projectStartMangeSetting, null)">添加开工信息</el-button> -->
                    <el-table :data="form.projectStartInfos">
                      <!-- <el-table-column label="施工许可编号" prop="permitNo"></el-table-column>
                      <el-table-column label="合同金额（万元）" prop="amount"></el-table-column>
                      <el-table-column label="面积（平方米）" prop="areaSize"></el-table-column>
                      <el-table-column label="发证日期" prop="giveLicenseTime">
                        <template #default="{ row: item }">{{
    parseTime(item.giveLicenseTime, "{yyyy}-{mm}-{dd}")
  }}</template>
                      </el-table-column> -->
                      <el-table-column label="开工日期" prop="logTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.logTime, "{yyyy}-{mm}-{dd}")
                        }}</template>
                      </el-table-column>
                      <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <a v-if="item.attach" :download="item.attach.fileName" :href="item.attach.filePath"
                            target="_blank">{{ item.attach.fileName }}</a>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期" prop="createTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                        }}</template>
                      </el-table-column>
                     
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="项目自查">
                  </el-tab-pane>
                  <el-tab-pane label="自查初验信息">
                    <!-- <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(firstCheckSetting, null)">添加自查初验信息</el-button> -->
                    <el-table :data="form.firstCheckInfos">
                      <el-table-column label="初验日期" prop="checkTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.checkTime, "{yyyy}-{mm}-{dd}")
                        }}</template>
                      </el-table-column>
                      <el-table-column label="初验结论" prop="checkResult"></el-table-column>
                      <el-table-column label="整改说明" prop="doUnit"></el-table-column>
                      <!-- <el-table-column label="预决算报告"></el-table-column>
                      <el-table-column label="监理报告"></el-table-column>

                      <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <p>
                            <a v-if="item.expertAdvise" :download="item.expertAdvise.fileName"
                              :href="item.expertAdvise.filePath" target="_blank">{{ item.expertAdvise.fileName }}</a>
                          </p>
                          <p>
                            <a v-if="item.amountReport" :download="item.amountReport.fileName"
                              :href="item.amountReport.filePath" target="_blank">{{ item.amountReport.fileName }}</a>
                          </p>
                          <p>
                            <a v-if="item.watchReport" :download="item.watchReport.fileName"
                              :href="item.watchReport.filePath" target="_blank">{{ item.watchReport.fileName }}</a>
                          </p>
                          <p>
                            <a v-if="item.elseFile" :download="item.elseFile.fileName" :href="item.elseFile.filePath"
                              target="_blank">{{ item.elseFile.fileName }}</a>
                          </p>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期">
                        <template #default="{ row: item }">{{
    parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
  }}</template>
                      </el-table-column> -->
                      <el-table-column>
                        <template #default="{ row: item }">
                          <el-button type="primary" icon="el-icon-edit" size="mini" circle
                            @click="manageShow(firstCheckSetting, item)"></el-button>

                          <el-popconfirm title="确认删除吗!?" @confirm="
                            removeFromList(form.firstCheckInfos, item)
                            ">
                            <template #reference>
                              <el-button type="danger" size="mini" circle>
                                <el-tooltip content="删除">
                                  <i class="el-icon-delete"></i>
                                </el-tooltip>
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>

                  <el-tab-pane label="四方验评">
                    <!-- <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(unionCheckSetting, { designUnit: form.project_DesignUnit, doUnit: form.project_DoUnit, watchUnit: form.project_WatchUnit, ownerUnit: form.project_ManageUnit }, 'add')">添加四方验评信息</el-button> -->
                    <el-table :data="form.unionCheckInfos">
                      <el-table-column label="验评日期" prop="checkTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.checkTime, "{yyyy}-{mm}-{dd}")
                        }}</template>
                      </el-table-column>
                      <!-- <el-table-column label="验评结论" prop="checkResult"></el-table-column>

                      <el-table-column label="设计单位" prop="designUnit"></el-table-column>
                      <el-table-column label="施工单位" prop="doUnit"></el-table-column>
                      <el-table-column label="监理单位" prop="watchUnit"></el-table-column>
                      <el-table-column label="管理单位" prop="ownerUnit"></el-table-column> -->

                      <el-table-column label="验评报告" prop="file">
                        <template #default="{ row: item }">

                          <p>
                            <a v-if="item.file" :download="item.file.fileName" :href="item.file.filePath"
                              target="_blank">{{ item.file.fileName }}</a>
                          </p>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                        }}</template>
                      </el-table-column>
                    
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="资金支出" name="fund" v-if="setting.active_Name == 'fund'">
          <FundRunSubmit v-model="fundWatches[i]" v-for="(item, i) in fundWatches" :id="item.id" :key="item.id"
            :ref="(el) => setFundSubmitInRefs(el, item.id)" style="width: 100%"></FundRunSubmit>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <BiddingManage :setting="biddingManageSetting" @change="(arg) => onListManged(form.biddingInfos, arg)"
      v-if="biddingManageSetting.visible"></BiddingManage>

    <ContractMange :setting="contractMangeSetting" @change="(arg) => onListManged(form.contractInfos, arg)"
      v-if="contractMangeSetting.visible"></ContractMange>

    <FirstCheckMange :setting="firstCheckSetting" @change="(arg) => onListManged(form.firstCheckInfos, arg)"
      v-if="firstCheckSetting.visible"></FirstCheckMange>
    <ProjectStartMange :setting="projectStartMangeSetting" @change="(arg) => onListManged(form.projectStartInfos, arg)"
      v-if="firstCheckSetting.visible"></ProjectStartMange>
  </el-dialog>
</template>

<script lang="ts">
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import {
  fundWatchProjectDoStatusDic,
  fundWatchSourceDic,
} from "@/network/lookUp.ts";
import { parseTime } from "@/utils/index.js";
import FundRunSubmit from "./fundRunSubmit.vue";
import BiddingManage from "./biddingManage.vue";
import ContractMange from "./contractMange.vue";
import FirstCheckMange from "./firstCheckMange.vue";
import ProjectStartMange from "./projectStartMange.vue";
// import { reactiveShowSetting } from "@/types/showSetting.ts";
import { useFundWatchAction } from "@/network/fundWatch";
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from "vue";

export default defineComponent({
  components: {
    FundRunSubmit,
    BiddingManage,
    ContractMange,
    FirstCheckMange,
    ProjectStartMange,
    FileuploadListV1
  },
  emits: ["change"],
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const activeName = ref(props.setting.active_Name || "project");
    const [isProcessing, , , , projectUpdate, , getfundWatches, projectFind] =
      useFundWatchAction();
    // const showSetting = reactiveShowSetting();
    const biddingManageSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const contractMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const projectStartMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const firstCheckSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const activeNames = ref(["1", "2", "3", "4"]);
    const attachmentType = ref(null);
    const form = reactive({
      id: null,
      biddingInfos: [],
      contractInfos: [],
      projectStartInfos: [],
      firstCheckInfos: [],
      unionCheckInfos: [],
      attachList: []
    });

    const fundWatches = ref([]);

    const { visible, id } = toRefs(props.setting as any);

    const manageShow = (setting, item) => {
      setting.action = item == null ? "add" : "update";
      setting.visible = true;
      setting.form = item;
    };

    const removeFromList = (list, item) => {
      const index = list.findIndex((p) => item == p);
      if (index >= -1) {
        list.splice(index, 1);
      }
    };

    const onListManged = (list, { action, data }) => {
      //console.log(action, data);
      if (action == "add") {
        data.createTime = new Date();
        // data.createTime = parseTime(new Date(), "{yyyy}-{mm}-{dd}");
        // console.log(data,parseTime(new Date(), "{yyyy}-{mm}-{dd}"))
        list.push(data);
      }
    };

    onMounted(() => {
      isLoading.value = true;
      activeName.value = props.setting.active_Name || "project";
      projectFind(props.setting.id).then((p) => {
        Object.assign(form, p);
        isLoading.value = false;
      });

      getfundWatches(props.setting.id).then((p) => {
        fundWatches.value = p;
      });
    });

    const save = () => {
      projectUpdate(id.value, form).then((o) => {
        emit("change");
      });
    };

    return {
      fundWatchProjectDoStatusDic,
      fundWatchSourceDic,
      isLoading,
      activeName,
      parseTime,
      isProcessing,
      manageShow,
      removeFromList,
      save,
      biddingManageSetting,
      contractMangeSetting,
      projectStartMangeSetting,
      firstCheckSetting,
      onListManged,
      // showSetting,
      activeNames,
      attachmentType,
      visible,
      confirm,
      form,
      fundWatches,
    };
  },
});
</script>

<style scoped></style>
