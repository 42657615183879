<template>
  <el-dialog v-model="visible" title="招标信息">
    <el-card>
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <!-- <el-form-item label="中标单位" prop="bidedUnitName">
            <el-input v-model="form.bidedUnitName" placeholder="中标单位"></el-input>
          </el-form-item> -->
          <el-form-item label="是否招标" prop="biddingType">
            <!-- <el-input v-model="form.biddingType" placeholder="招标类型"></el-input> -->
            <el-select v-model="form.biddingType" placeholder="请选择">
              <el-option labe="未招标" value="未招标"></el-option>
              <el-option labe="公开招标" value="公开招标"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="中标金额(万元)" prop="bidedAmount">
            <el-input v-model="form.bidedAmount" placeholder="中标金额(万元)"
              @input="(v) => inputChangeEmptyToNull(v, form, 'bidedAmount')"></el-input>(万元)
          </el-form-item>
          <!-- <el-form-item label="招标方式" prop="biddingMethod">
            <el-input v-model="form.biddingMethod" placeholder="招标方式"></el-input>
          </el-form-item>
          <el-form-item label="中标日期" prop="bidedDate">
            <el-date-picker v-model="form.bidedDate" placeholder="中标日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="中标通知书编号" prop="bidedFileNo">
            <el-input v-model="form.bidedFileNo" placeholder="中标通知书编号"></el-input>
          </el-form-item> -->
          <el-form-item label="附件" prop="Attach">
            <SingleFileupload :setting="fileSetting"></SingleFileupload>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" icon="el-icon-check" @click="save">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import SingleFileupload from "@/components/singleFileupload";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
import {
  numberRulesNoRequired,
  inputChangeEmptyToNull,
} from "@/model/fundFormRules.ts";
export default defineComponent({
  components: { SingleFileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, form, action } = toRefs(props.setting as any);
    const rules = {
      bidedAmount: numberRulesNoRequired,
    };
    const fileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {

    //   }
    // });

    onMounted(() => {
      if (!form.value) form.value = {};
      else {
        fileSetting.fileEmbed = form.value.attach;
      }
      nextTick(() => {
        setTimeout(() => refForm.value.clearValidate(), 1);
      })


    });
    const save = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          // if (fileSetting.fileEmbed) {
          //   form.value.attach = fileSetting.fileEmbed;
          // }
          form.value.attach = fileSetting.fileEmbed;
          emit("change", { action: action.value, data: form.value });
          console.log(form.value);
          visible.value = false;
        }
      });
    };

    return {
      rules,
      inputChangeEmptyToNull,
      fileSetting,
      user,
      save,
      refForm,
      visible,
      form,
    };
  },
});
</script>

<style scoped></style>
