<template>
  <el-dialog v-model="visible" title="四方验评">
    <el-card>
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm">
          <el-form-item label="验评日期" prop="checkTime">
            <el-date-picker v-model="form.checkTime" placeholder="验评日期"></el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="验评结论" prop="checkResult">
            <el-input v-model="form.checkResult" placeholder="验评结论"></el-input>
          </el-form-item>
          <el-form-item label="设计单位" prop="designUnit">
            <el-input v-model="form.designUnit" placeholder="设计单位"></el-input>
          </el-form-item>

          <el-form-item label="施工单位" prop="doUnit">
            <el-input v-model="form.doUnit" placeholder="施工单位"></el-input>
          </el-form-item>
          <el-form-item label="监理单位" prop="watchUnit">
            <el-input v-model="form.watchUnit" placeholder="监理单位"></el-input>
          </el-form-item>
          <el-form-item label="管理单位" prop="ownerUnit">
            <el-input v-model="form.ownerUnit" placeholder="管理单位"></el-input>
          </el-form-item> -->

          <el-form-item label="验评文件" prop="file">
            <SingleFileupload :setting="filesSetting"></SingleFileupload>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" icon="el-icon-check" @click="save">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import SingleFileupload from "@/components/singleFileupload";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
export default defineComponent({
  components: { SingleFileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, form, action } = toRefs(props.setting as any);

    const filesSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });

    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {

    //   }
    // });

    onMounted(() => {
      if (!form.value) form.value = {};
      else {

        filesSetting.fileEmbed = form.value.file;
      }
      nextTick(() => {
        setTimeout(() => refForm.value.clearValidate(), 1);
      })
    });
    const save = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          form.value.file = filesSetting.fileEmbed;
          emit("change", { action: action.value, data: form.value });
          visible.value = false;
        }
      });
    };

    return {
      filesSetting,
      user,
      save,
      refForm,
      visible,
      form,
    };
  },
});
</script>

<style scoped></style>
