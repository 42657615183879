<template>
  <!-- {{ fileList }} -->
  <div v-for="item in fileList" :key="item[urlKey]">
    <el-space>
      <a :href="item[urlKey]" target="_blank" :download="item[nameKey]">
        <i class="el-icon-link"></i>
        {{ item[nameKey] }}
      </a>
      <el-popconfirm title="确认删除吗！？" @confirm="onRemoveFile(item)">
        <template #reference>
          <el-button type="text" icon="el-icon-close" style="color: red"></el-button>
        </template>
      </el-popconfirm>
    </el-space>
  </div>
  <el-upload ref="refUpload" :limit="limit" :before-upload="onBeforeUpload" :on-exceed="onExceed"
    :on-progress="onProgress" :action="action" multiple :on-success="onSuccess" :accept="accpet">
    <div style="text-align:left">
      <el-button type="primary">{{ btnText }}</el-button>
      <slot name="tip">
        <div class="el-upload__tip" v-if="showTip">请上传{{ accpet }}文件</div>
      </slot>
    </div>
  </el-upload>
  <!-- <el-upload
    action="."
    :file-list="[{ name: 'test', url: 'xxx' }]"
    :on-remove="
      () => {
        alert(111);
      }
    "
  >
    <el-button type="primary">xxx</el-button>
  </el-upload>-->
</template>

<script lang="ts">

import { getToken } from "@/utils/auth";
import { strMBFormat, previewOrDownload } from "@/utils/util";
import settings from "@/settings";
import { ElMessage } from "element-plus";
import type { PropType } from 'vue'
import {
  defineComponent,
  computed,
  useContext,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
} from "vue";

export default defineComponent({
  props: {
    action: { type: String, default: settings.uploadAction },
    limit: { type: Number, default: undefined },
    accpet: { type: String, default: settings.attachment.accept },
    files: {
      type: Array as PropType<Array<any>>,
    },
    fileGroup: { type: String, default: null },
    showTip: { type: Boolean, default: true },
    btnText: { type: String, default: "点击上传" },
    urlKey: { type: String, default: "url" },
    nameKey: { type: String, default: "name" },
  },
  emits: ["remove", "upload", "progress", "update:files"],
  expose: ["isFileUploading", "clear"],
  setup(
    props, {
      emit
    }
  ) {

    const { limit, fileGroup, urlKey, nameKey, files } = toRefs(props) as any;
    const refUpload = ref(null);
    const fileList = computed(
      () =>
        files.value.filter(
          (p) => props.fileGroup == null || p.type == fileGroup.value
        ) || []
    );
    const isFileUploading = ref(false);




    const onExceed = () => {
      ElMessage.error(`仅允许同时上传${limit.value}份文件！`);
    };
    const onBeforeUpload = (file) => {
      if (fileList.value.length >= limit.value) {
        ElMessage.error(
          `仅允许上传${limit.value}份附件，如需修改请删除原有后重新上传！`
        );
        return false;
      }
      return true;
    };
    const onRemoveFile = (file) => {
      const index = files.value?.findIndex(
        (p) =>
          p[urlKey.value] == file[urlKey.value] &&
          (fileGroup.value == null || p.type == fileGroup.value)
      );
      if (index > -1) files.value.splice(index, 1);
      emit("remove", file);
    };
    const onSuccess = (response, file) => {
      if (response.code == 200) {
        isFileUploading.value = false;
        file.url = response.data;
        const arg = {
          [urlKey.value as any]: response.data,
          [nameKey.value as any]: file.name,
        };
        emit("upload", arg);
        refUpload.value.clearFiles();

        files.value.push(
          fileGroup.value != null
            ? Object.assign(arg, { type: fileGroup.value })
            : arg
        );
      }
    };
    const onProgress = () => {
      isFileUploading.value = true;
      emit("progress");
    };
    const clear = () => {
      //console.log("aaa");
      emit(
        "update:files",
        files.value.filter((p) => p.type != fileGroup.value)
      );

    }
    return {
      isFileUploading,
      fileList,
      refUpload,
      onExceed,
      onBeforeUpload,
      onRemoveFile,
      onSuccess,
      onProgress,
      clear
    }
  }
})
// const { expose } = useContext();
// expose({
//   isFileUploading,
//   clear: () => {
//     //console.log("aaa");
//     emit(
//       "update:files",
//       files.value.filter((p) => p.type != fileGroup.value)
//     );

//     //fileList.value = [];
//   },
// });
</script>

<style></style>
