<template>
  <el-dialog v-model="visible" title="初验信息">
    <el-card>
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm">
          <el-form-item label="初验日期" prop="checkTime">
            <el-date-picker
              v-model="form.checkTime"
              placeholder="初验日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="初验意见" prop="checkResult">
            <el-input
              v-model="form.checkResult"
              placeholder="初验意见"
                type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="整改说明" prop="doUnit">
            <el-input
              v-model="form.doUnit"
              placeholder="整改说明"
              type="textarea"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="施工单位" prop="doUnit">
            <el-input v-model="form.doUnit" placeholder="施工单位"></el-input>
          </el-form-item>
          <el-form-item label="监理单位" prop="watchUnit">
            <el-input
              v-model="form.watchUnit"
              placeholder="监理单位"
            ></el-input>
          </el-form-item>

          <el-form-item label="专家意见" prop="expertAdvise">
            <SingleFileupload
              :setting="expertAdviseFileSetting"
            ></SingleFileupload>
          </el-form-item>
          <el-form-item label="预决算报告" prop="amountReport">
            <SingleFileupload
              :setting="amountReportFileSetting"
            ></SingleFileupload>
          </el-form-item>
          <el-form-item label="监理报告" prop="watchReport">
            <SingleFileupload
              :setting="watchReportFileSetting"
            ></SingleFileupload>
          </el-form-item>
          <el-form-item label="其他" prop="elseFile">
            <SingleFileupload :setting="elseFileFileSetting"></SingleFileupload>
          </el-form-item> -->
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" icon="el-icon-check" @click="save"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
// import SingleFileupload from "@/components/singleFileupload";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
export default defineComponent({
  //components: { SingleFileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, form, action } = toRefs(props.setting as any);
    const expertAdviseFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const amountReportFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const watchReportFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const elseFileFileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });

    //console.log(form.value, expertAdviseFileSetting.fileEmbed);
    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {

    //   }
    // });

    onMounted(() => {
      if (!form.value) form.value = {};
      else {
        expertAdviseFileSetting.fileEmbed = form.value.expertAdvise;
        amountReportFileSetting.fileEmbed = form.value.amountReport;
        watchReportFileSetting.fileEmbed = form.value.watchReport;
        elseFileFileSetting.fileEmbed = form.value.elseFile;
      }
      nextTick(() => {
        setTimeout(() => refForm.value.clearValidate(), 1);
      })


    });
    const save = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          form.value.expertAdvise = expertAdviseFileSetting.fileEmbed;
          form.value.amountReport = amountReportFileSetting.fileEmbed;
          form.value.watchReport = watchReportFileSetting.fileEmbed;
          form.value.elseFile = elseFileFileSetting.fileEmbed;

          emit("change", { action: action.value, data: form.value });
          visible.value = false;
        }
      });
    };

    return {
      expertAdviseFileSetting,
      amountReportFileSetting,
      watchReportFileSetting,
      elseFileFileSetting,
      user,
      save,
      refForm,
      visible,
      form,
    };
  },
});
</script>

<style scoped></style>
