import { isvalidatemobile, isNumber, isEmpty } from "@/utils/validate";
import { Ref, watch, ref, onMounted } from "vue";
import { useCheckUnique } from "@/network/officialDoc";

// const validateArr = (rule, value, callback) => {
//   if (value === "") {
//     callback(new Error("请选择城市"));
//   } else {
//     // if (this.ruleForm.checkPass !== "") {
//     //   this.$refs.ruleForm.validateField("checkPass");
//     // }
//     callback();
//   }
// };

const base = {
  heritageUnit_Id: [
    { required: true, message: "请选择文保单位！", trigger: "changed" },
  ],
  project_Classify_Id: [
    { required: true, message: "请选择项目类型！", trigger: "change" },
  ],
  project_SubClassify_Id: [
    { required: true, message: "请选择项目子类型！", trigger: "change" },
  ],
  // proposalDoc_Id: [
  //   { required: true, message: "请选择方案批准文件！", trigger: "change" },
  // ],
  project_Name: [
    {
      required: true,
      message: "请输入项目名称！",
      trigger: "blur",
    },
    {
      min: 5,
      max: 100,
      message: "项目名称长度必须大于5小于100",
      trigger: "blur",
    },
  ],
  // budget_Total: [
  //   {
  //     required: true,
  //     message: "请填写项目总概算!",
  //     trigger: "blur",
  //   },
  // ],
  province_Apply_Count: [
    {
      required: true,
      message: "填报不正确，请填写数字",
      trigger: "blur",
      validator: (rule, value, callback) => {
        if (isEmpty(value) || !isNumber(value)) {
          return callback(Error(rule.message));
        }
        callback();
      },
    },
  ],
  country_Apply_Count: [
    {
      required: true,
      message: "填报不正确，请填写数字",
      trigger: "blur",
      validator: (rule, value, callback) => {
        if (isEmpty(value) || !isNumber(value)) {
          return callback(Error(rule.message));
        }
        callback();
      },
    },
  ],
  other_Apply_Count: [
    {
      required: true,
      message: "填报不正确，请填写数字",
      trigger: "blur",
      validator: (rule, value, callback) => {
        if (isEmpty(value) || !isNumber(value)) {
          return callback(Error(rule.message));
        }
        callback();
      },
    },
  ],
  execute_Unit_Name: [
    {
      required: true,
      message: "请填写资金申请单位!",
      trigger: "blur",
    },
  ],
  fund_Manage_Unit: [
    {
      required: true,
      message: "请填写资金管理单位!",
      trigger: "blur",
    },
  ],
  implement_Unit: [
    {
      required: true,
      message: "请填写项目实施单位!",
      trigger: "blur",
    },
  ],
  project_Compile_Unit_Name: [
    {
      required: true,
      message: "请填写法人代表!",
      trigger: "blur",
    },
  ],
  project_Manager: [
    {
      required: true,
      message: "请填写项目负责人!",
      trigger: "blur",
    },
  ],
  manager_Cell_Phone: [
    {
      required: true,
      message: "手机号输入不正确!",
      validator: (rule, value, callback) => {
        const r = isvalidatemobile(value);
        //console.log(r);
        if (r[0]) {
          callback(new Error(rule.message));
        } else {
          callback();
        }
      },
      trigger: "blur",
    },
  ],
  superior_Name: [
    {
      required: true,
      message: "请填写项目联系人!",
      trigger: "blur",
    },
  ],
  superior_Contact: [
    {
      required: true,
      message: "手机号输入不正确!",
      validator: (rule, value, callback) => {
        const r = isvalidatemobile(value);
        if (r[0]) {
          callback(new Error(rule.message));
        } else {
          callback();
        }
      },
      trigger: "blur",
    },
  ],
  compile_Unit_Name: [
    {
      required: true,
      message: "请填写预算编制单位!",
      trigger: "blur",
    },
  ],
  unit_Brief_Introduce: [
    {
      required: true,
      message: "请填写管理单位简介!",
      trigger: "blur",
    },
  ],
  brief_Description: [
    {
      required: true,
      message: "请填写保护单位基本情况!",
      trigger: "blur",
    },
  ],
  past_Protect_Description: [
    {
      required: true,
      message: "请填写历年保护维修情况!",
      trigger: "blur",
    },
  ],
  project_Content: [
    {
      required: true,
      message: "请填写项目拟实施内容简要介绍!",
      trigger: "blur",
    },
  ],
  annual_Execute_Plan: [
    {
      required: true,
      message: "请填写分年度工作计划!",
      trigger: "blur",
    },
  ],
  // projectAttach: [
  //   {
  //     require: true,
  //     validator: () => {},
  //   },
  // ],
};

export function useFundFormRules(refForm): Array<any> {
  let model: any = null;
  const rules = ref(
    Object.assign(
      {
        budget_Total: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              //console.log(model, value, isEmpty(value) || !isNumber(value));
              if (isEmpty(value) || !isNumber(value)) {
                callback(new Error("总概算填写错误！"));
              }
              console.log(value);
              //model.budget_Total = Number(model.budget_Total);
              if (
                Number(model.budget_Total) <
                Number(model.province_Apply_Count) +
                  Number(model.country_Apply_Count) +
                  Number(model.other_Apply_Count)
              ) {
                //console.log("bbbb");
                callback(new Error("总概算不能小于申报预算"));
              } else {
                callback();
              }
            },
          },
        ],
        proposalDoc_Id: [
          {
            required: true,
            //message: "请选择方案批准文件！",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (model.needProposalDoc) {
                if (!value) {
                  return callback(Error("请选择方案批准文件！"));
                } else {
                  const { check } = useCheckUnique();
                  check(value, model.id).then((error) => {
                    if (error === true) {
                      //console.log("该批文已被其他项目使用！")
                      callback(Error("该批文已被其他项目使用！"));
                    } else {
                      callback();
                    }
                  });
                }
              } else {
                callback();
              }
            },
          },
        ],
        noDoc_Note: [
          {
            required: true,
            message: "请填写无方案批准文件说明！",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!model.needProposalDoc) {
                if (value == "" || value == null) {
                  return callback(Error(rule.message));
                }
              }

              callback();
            },
          },
        ],
      },
      base
    )
  );

  const attachRules = (i, isRequired) => {
    return [
      {
        required: isRequired,
        message: "请上传附件！",
        validator: (rule, value, callback) => {
          if (isRequired) {
            if (value.fileAttach?.file_Path == null) {
              return callback(Error(rule.message));
            }
          }
          callback();
        },
      },
    ];
  };
  // watch(rules, () => {
  //   setTimeout(() => {
  //     //refForm.value.clearValidate();
  //   }, 1);
  // });

  const setFormRule = () => {
    model = refForm.value.model;
    //rules.value = Object.assign(rules.value, );
  };

  return [rules, attachRules, setFormRule];
}

export const performanceSubmitRule = {
  provDept: [
    {
      required: true,
      message: "不能为空！",
      trigger: "blur",
    },
  ],
  doDept: [
    {
      required: true,
      message: "不能为空！",
      trigger: "blur",
    },
  ],
  yearAmount: [
    {
      required: true,
      message: "填报不正确，请填写数字",
      trigger: "blur",
      validator: (rule, value, callback) => {
        if (isEmpty(value) || !isNumber(value)) {
          return callback(Error(rule.message));
        }
        callback();
      },
    },
  ],
  fiscalAmount: [
    {
      required: true,
      message: "填报不正确，请填写数字",
      trigger: "blur",
      validator: (rule, value, callback) => {
        if (isEmpty(value) || !isNumber(value)) {
          return callback(Error(rule.message));
        }
        callback();
      },
    },
  ],
  aim: [
    {
      required: true,
      message: "总体目标不能为空！",
      trigger: "blur",
    },
  ],
};

export const performanceScoreRule = {
  result: [
    {
      required: true,
      message: "请选择综合评定等级",
      trigger: "change",
    },
  ],
};

export const fundApplyRule = {
  allocateAmount: {
    required: true,
    message: "填报不正确，请填写数字",
    trigger: "blur",
    validator: (rule, value, callback) => {
      if (isEmpty(value) || !isNumber(value)) {
        return callback(Error(rule.message));
      }
      callback();
    },
  },

  applyAmount: [
    {
      required: true,
      message: "填报不正确，请填写数字",
      trigger: "blur",
      validator: (rule, value, callback) => {
        if (isEmpty(value) || !isNumber(value)) {
          return callback(Error(rule.message));
        }
        callback();
      },
    },
  ],
};

export const numberRules = [
  {
    required: true,
    message: "填报不正确，请填写数字",
    trigger: "blur",
    validator: (rule, value, callback) => {
      if (isEmpty(value) || !isNumber(value)) {
        return callback(Error(rule.message));
      }
      callback();
    },
  },
];

export const numberRulesNoRequired = [
  {
    message: `填报不正确，请填写数字`,
    trigger: "change",
    validator: (rule, value, callback) => {
      if (!isEmpty(value) && !isNumber(value)) {
        return callback(Error(rule.message));
      }
      callback();
    },
  },
];

export const inputChangeEmptyToNull = (v, item, key) => {
  //console.log(v, item, key);
  if (v == "") {
    item[key] = null;
    //console.log("change", item);
  }
};
