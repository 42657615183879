<template>
  <el-dialog v-model="visible" title="合同登记信息">
    <el-card>
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-form-item label="合同类别" prop="contractType">
            <el-radio-group v-model="form.contractType">
              <el-radio-button label="勘察"></el-radio-button>
              <el-radio-button label="设计"></el-radio-button>
              <el-radio-button label="施工"></el-radio-button>
              <!-- <el-radio-button label="监理"></el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="合同登记编号" prop="contractNo">
            <el-input v-model="form.contractNo" placeholder="合同登记编号"></el-input>
          </el-form-item> -->
          <el-form-item label="合同金额(万元)" prop="contractAmount">
            <el-input v-model="form.contractAmount" placeholder="合同金额(万元)"
              @input="(v) => inputChangeEmptyToNull(v, form, 'contractAmount')"></el-input>(万元)
          </el-form-item>
          <el-form-item label="中标单位名称" prop="contractUnit">
            <el-input v-model="form.contractUnit" placeholder="中标单位名称"></el-input>
          </el-form-item>
          <!-- <el-form-item label="发包单位名称" prop="contractUnit">
            <el-input v-model="form.contractUnit" placeholder="发包单位名称"></el-input>
          </el-form-item>
          <el-form-item label="承包单位名称" prop="contractor">
            <el-input v-model="form.contractor" placeholder="承包单位名称"></el-input>
          </el-form-item> -->
          <el-form-item label="附件" prop="attach">
            <SingleFileupload :setting="fileSetting"></SingleFileupload>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" icon="el-icon-check" @click="save">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import SingleFileupload from "@/components/singleFileupload";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import settings from "@/settings";
import {
  numberRulesNoRequired,
  inputChangeEmptyToNull,
} from "@/model/fundFormRules.ts";
export default defineComponent({
  components: { SingleFileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const rules = {
      contractAmount: numberRulesNoRequired,
    };
    const user: any = inject("user");
    const { visible, form, action } = toRefs(props.setting as any);
    const fileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {

    //   }
    // });
    onMounted(() => {
      if (!form.value) form.value = {};
      else {
        fileSetting.fileEmbed = form.value.attach;
      }
      nextTick(() => {
        setTimeout(() => refForm.value.clearValidate(), 1);
      })


    });
    const save = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          // if (fileSetting.fileEmbed) {
          //   form.value.attach = fileSetting.fileEmbed;
          // }
          form.value.attach = fileSetting.fileEmbed;
          emit("change", { action: action.value, data: form.value });
          visible.value = false;
        }
      });
    };

    return {
      rules,
      inputChangeEmptyToNull,
      fileSetting,
      user,
      save,
      refForm,
      visible,
      form,
    };
  },
});
</script>

<style scoped></style>
