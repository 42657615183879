<template>
  <el-dialog v-model="visible" title="文物保护项目进展情况登记表" width="90%">
    <el-alert title="各处金额、面积仅需填写数字，无需填写 元/万元/米/平方米 等相关文字后缀" type="warning" show-icon />
    <el-card v-loading="isLoading">
      <el-tabs tab-position="left" v-model="activeName">
        <el-tab-pane label="项目进度情况" name="project" v-if="setting.active_Name == 'project'">
          <el-collapse v-model="activeNames">
            <el-collapse-item name="1" title="基本信息">
              <el-form :model="form" label-width="200px" :rules="rules">
                <!-- <el-form-item label="时期">{{ form.age }}</el-form-item> -->
                <el-form-item label="市级">{{ form.city_Name }}</el-form-item>
                <el-form-item label="区县">{{
                  form.country_Name
                  }}</el-form-item>

                <!-- {{
                  form.heritageUnit_Name
                }} -->

                <el-form-item label="文保单位">
                  <SelectUnit :setting="selectUnitSetting" @change="selectUnitChange">
                  </SelectUnit>
                </el-form-item>

                <el-form-item label="可移动/不可移动">
                  <el-radio-group v-model="form.project_Type">
                    <el-radio-button label="可移动"></el-radio-button>
                    <el-radio-button label="不可移动"></el-radio-button>

                    <!-- <el-radio-button label="监理"></el-radio-button> -->
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-collapse-item>
            <!-- <el-collapse-item name="2" title="计划书">
              <el-form :model="form" label-width="200px" :rules="rules" ref="refForm2">
                <el-form-item label="计划书批复名称" prop="setupDoc_Title">
                  <el-input v-model="form.setupDoc_Title"></el-input>
                </el-form-item>
                <el-form-item label="计划书日期">
                  <el-date-picker v-model="form.setupDoc_Date"></el-date-picker>
                </el-form-item>
                <el-form-item label="业主单位名称">
                  <el-input v-model="form.project_Unit_Name" placeholder="请填写"></el-input>
                </el-form-item>
                <el-form-item label="批复文号" prop="setupDoc">
                  <el-input v-model="form.setupDoc"></el-input>
                </el-form-item>
              </el-form>
            </el-collapse-item>
            <el-collapse-item name="3" title="技术方案情况">
              <el-form :model="form" label-width="200px" :rules="rules" ref="refForm3">
                <el-form-item label="技术方案进展">
                  <el-select v-model="form.proposalDoc_Status">
                    <el-option label="未编制" value="未编制"></el-option>
                    <el-option label="技术方案编制中" value="技术方案编制中"></el-option>
                    <el-option label="方案审批中" value="方案审批中"></el-option>
                    <el-option label="获得方案批复" value="获得方案批复"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="技术方案名称" prop="proposalDoc_Title">
                  <el-input v-model="form.proposalDoc_Title"></el-input>
                </el-form-item>
                <el-form-item label="方案编制单位">
                  <el-input v-model="form.proposalDoc_DesignUnit"></el-input>
                </el-form-item>
                <el-form-item label="方案编制日期">
                  <el-date-picker v-model="form.proposalDoc_WriteDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="方案批复日期">
                  <el-date-picker v-model="form.proposalDoc_AccessDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="方案批复文号" prop="proposalDoc">
                  <el-input v-model="form.proposalDoc"></el-input>
                </el-form-item>
              </el-form>
            </el-collapse-item> -->
            <el-collapse-item name="4" title="工程实施情况">
              <el-form :model="form" label-width="200px" ref="refForm4" :rules="rules">
                <!-- <el-form-item label="项目名称">
                  <el-input v-model="form.project_Name" :disabled="true"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="项目编号">
                  <el-input v-model="form.project_Code"></el-input>
                </el-form-item>

                <el-form-item label="文物类别">
                  <el-input v-model="form.heritage_Type"></el-input>
                </el-form-item>
                <el-form-item label="项目类别">
                  <el-input v-model="form.project_Type"></el-input>
                </el-form-item> -->
                <el-form-item label="项目负责人">
                  <el-input v-model="form.project_Manager"></el-input>
                </el-form-item>
                <!-- <el-form-item label="职务">
                  <el-input v-model="form.project_Manager_Job"></el-input>
                </el-form-item> -->
                <el-form-item label="联系电话">
                  <el-input v-model="form.project_Manager_Phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="form.project_Manager_Email"></el-input>
                </el-form-item>
                <!-- <el-form-item label="财务负责人">
                  <el-input v-model="form.project_FundManager"></el-input>
                </el-form-item>
                <el-form-item label="职务">
                  <el-input v-model="form.project_FundManager_Job"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input v-model="form.project_FundManager_Phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="form.project_FundManager_Email"></el-input>
                </el-form-item> -->
                <el-form-item label="进展状态" prop="project_DoStatus">
                  <el-select v-model="form.project_DoStatus">
                    <el-option :key="item" :label="item" :value="item"
                      v-for="item in fundWatchProjectDoStatusDic"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="进展描述" prop="questionDetail">
                  <el-input type="textarea" v-model="form.questionDetail"></el-input>
                </el-form-item>

                <!-- <el-form-item label="工程经费(万元)" prop="project_Fund_Amount">
                  <el-input v-model="form.project_Fund_Amount" @input="(v) =>
    inputChangeEmptyToNull(v, form, 'project_Fund_Amount')
    " style="width: 220px"></el-input>
                </el-form-item> -->
                <el-form-item label="经费来源" prop="project_Fund_Source">
                  <el-select v-model="form.project_Fund_Source" :disabled="true">
                    <el-option :label="item" :value="item" v-for="item in fundWatchSourceDic" :key="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="施工单位" prop="project_DoUnit">
                  <el-input v-model="form.project_DoUnit"></el-input>
                </el-form-item>
                <el-form-item label="设计单位" prop="project_DesignUnit">
                  <el-input v-model="form.project_DesignUnit"></el-input>
                </el-form-item>
                <el-form-item label="监理单位" prop="project_WatchUnit">
                  <el-input v-model="form.project_WatchUnit"></el-input>
                </el-form-item>
                <el-form-item label="管理单位" prop="project_ManageUnit">
                  <el-input v-model="form.project_ManageUnit"></el-input>
                </el-form-item>
                <el-form-item label="开工日期">
                  <el-date-picker v-model="form.project_StartDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="完工日期">
                  <el-date-picker v-model="form.project_EndDate"></el-date-picker>
                </el-form-item>
                <el-form-item label="相关附件(图片、文档、资料)" prop="attachList">
                  <FileuploadListV1 v-model:files="form.attachList"
                    accpet=".zip,.rar,.pdf,.wps,.ofd,.doc,.docx,.jpg,.png,.gif" urlKey="filePath" nameKey="fileName">
                  </FileuploadListV1>
                </el-form-item>
                <el-tabs>
                  <el-tab-pane label="招标信息">
                    <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(biddingManageSetting, null)">添加招标信息</el-button>
                    <el-table :data="form.biddingInfos">
                      <!-- <el-table-column label="中标单位" prop="bidedUnitName"></el-table-column> -->
                      <el-table-column label="是否招标" prop="biddingType"></el-table-column>
                      <!-- <el-table-column label="招标方式" prop="biddingMethod"></el-table-column>
                      <el-table-column label="中标日期" prop="bidedDate">
                        <template #default="{ row: item }">{{
    parseTime(item.bidedDate, "{yyyy}-{mm}-{dd}")
  }}</template>
</el-table-column> -->
                      <el-table-column label="中标金额（万元）" prop="bidedAmount"></el-table-column>
                      <!-- <el-table-column label="中标通知书编号" prop="bidedFileNo"></el-table-column> -->
                      <!-- <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <a :download="item.attach.fileName" v-if="item.attach" :href="item.attach.filePath"
                            target="_blank">{{ item.attach.fileName }}</a>
                        </template>
                      </el-table-column> -->
                      <el-table-column label="提交日期" prop="createTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                          }}</template>
                      </el-table-column>
                      <el-table-column>
                        <template #default="{ row: item }">
                          <el-button type="primary" icon="el-icon-edit" size="mini" circle
                            @click="manageShow(biddingManageSetting, item)"></el-button>

                          <el-popconfirm title="确认删除吗!?" @confirm="removeFromList(form.biddingInfos, item)">
                            <template #reference>
                              <el-button type="danger" size="mini" circle>
                                <el-tooltip content="删除">
                                  <i class="el-icon-delete"></i>
                                </el-tooltip>
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="工程合同信息">
                    <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(contractMangeSetting, null)">添加工程合同信息</el-button>
                    <el-table :data="form.contractInfos">
                      <el-table-column label="合同类别" prop="contractType"></el-table-column>
                      <!-- <el-table-column label="合同登记编号" prop="contractNo"></el-table-column> -->
                      <el-table-column label="合同金额（万元）" prop="contractAmount"></el-table-column>
                      <el-table-column label="中标单位名称" prop="contractUnit"></el-table-column>
                      <!-- <el-table-column label="发包单位名称" prop="contractUnit"></el-table-column>
                      <el-table-column label="承包单位名称" prop="contractor"></el-table-column> -->
                      <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <a v-if="item.attach" :download="item.attach.fileName" :href="item.attach.filePath"
                            target="_blank">{{ item.attach.fileName }}</a>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期" prop="createTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                          }}</template>
                      </el-table-column>
                      <el-table-column>
                        <template #default="{ row: item }">
                          <el-button type="primary" icon="el-icon-edit" size="mini" circle
                            @click="manageShow(contractMangeSetting, item)"></el-button>

                          <el-popconfirm title="确认删除吗!?" @confirm="removeFromList(form.contractInfos, item)">
                            <template #reference>
                              <el-button type="danger" size="mini" circle>
                                <el-tooltip content="删除">
                                  <i class="el-icon-delete"></i>
                                </el-tooltip>
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="开工信息">
                    <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(projectStartMangeSetting, null)">添加开工信息</el-button>
                    <el-table :data="form.projectStartInfos">
                      <!-- <el-table-column label="施工许可编号" prop="permitNo"></el-table-column>
                      <el-table-column label="合同金额（万元）" prop="amount"></el-table-column>
                      <el-table-column label="面积（平方米）" prop="areaSize"></el-table-column>
                      <el-table-column label="发证日期" prop="giveLicenseTime">
                        <template #default="{ row: item }">{{
    parseTime(item.giveLicenseTime, "{yyyy}-{mm}-{dd}")
  }}</template>
                      </el-table-column> -->
                      <el-table-column label="开工日期" prop="logTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.logTime, "{yyyy}-{mm}-{dd}")
                          }}</template>
                      </el-table-column>
                      <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <a v-if="item.attach" :download="item.attach.fileName" :href="item.attach.filePath"
                            target="_blank">{{ item.attach.fileName }}</a>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期" prop="createTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                          }}</template>
                      </el-table-column>
                      <el-table-column>
                        <template #default="{ row: item }">
                          <el-button type="primary" icon="el-icon-edit" size="mini" circle
                            @click="manageShow(projectStartMangeSetting, item)"></el-button>

                          <el-popconfirm title="确认删除吗!?" @confirm="
                            removeFromList(form.projectStartInfos, item)
                            ">
                            <template #reference>
                              <el-button type="danger" size="mini" circle>
                                <el-tooltip content="删除">
                                  <i class="el-icon-delete"></i>
                                </el-tooltip>
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="项目自查">
                  </el-tab-pane>
                  <el-tab-pane label="自查初验信息">
                    <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(firstCheckSetting, null)">添加自查初验信息</el-button>
                    <el-table :data="form.firstCheckInfos">
                      <el-table-column label="初验日期" prop="checkTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.checkTime, "{yyyy}-{mm}-{dd}")
                          }}</template>
                      </el-table-column>
                      <el-table-column label="初验结论" prop="checkResult"></el-table-column>
                      <el-table-column label="整改说明" prop="doUnit"></el-table-column>
                      <!-- <el-table-column label="预决算报告"></el-table-column>
                      <el-table-column label="监理报告"></el-table-column>

                      <el-table-column label="附件" prop="attach">
                        <template #default="{ row: item }">
                          <p>
                            <a v-if="item.expertAdvise" :download="item.expertAdvise.fileName"
                              :href="item.expertAdvise.filePath" target="_blank">{{ item.expertAdvise.fileName }}</a>
                          </p>
                          <p>
                            <a v-if="item.amountReport" :download="item.amountReport.fileName"
                              :href="item.amountReport.filePath" target="_blank">{{ item.amountReport.fileName }}</a>
                          </p>
                          <p>
                            <a v-if="item.watchReport" :download="item.watchReport.fileName"
                              :href="item.watchReport.filePath" target="_blank">{{ item.watchReport.fileName }}</a>
                          </p>
                          <p>
                            <a v-if="item.elseFile" :download="item.elseFile.fileName" :href="item.elseFile.filePath"
                              target="_blank">{{ item.elseFile.fileName }}</a>
                          </p>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期">
                        <template #default="{ row: item }">{{
    parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
  }}</template>
                      </el-table-column> -->
                      <el-table-column>
                        <template #default="{ row: item }">
                          <el-button type="primary" icon="el-icon-edit" size="mini" circle
                            @click="manageShow(firstCheckSetting, item)"></el-button>

                          <el-popconfirm title="确认删除吗!?" @confirm="
                            removeFromList(form.firstCheckInfos, item)
                            ">
                            <template #reference>
                              <el-button type="danger" size="mini" circle>
                                <el-tooltip content="删除">
                                  <i class="el-icon-delete"></i>
                                </el-tooltip>
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>

                  <el-tab-pane label="四方验评">
                    <el-button type="primary" icon="el-icon-plus"
                      @click="manageShow(unionCheckSetting, { designUnit: form.project_DesignUnit, doUnit: form.project_DoUnit, watchUnit: form.project_WatchUnit, ownerUnit: form.project_ManageUnit }, 'add')">添加四方验评信息</el-button>
                    <el-table :data="form.unionCheckInfos">
                      <el-table-column label="验评日期" prop="checkTime">
                        <template #default="{ row: item }">{{
                          parseTime(item.checkTime, "{yyyy}-{mm}-{dd}")
                          }}</template>
                      </el-table-column>
                      <!-- <el-table-column label="验评结论" prop="checkResult"></el-table-column>

                      <el-table-column label="设计单位" prop="designUnit"></el-table-column>
                      <el-table-column label="施工单位" prop="doUnit"></el-table-column>
                      <el-table-column label="监理单位" prop="watchUnit"></el-table-column>
                      <el-table-column label="管理单位" prop="ownerUnit"></el-table-column> -->

                      <el-table-column label="验评报告" prop="file">
                        <template #default="{ row: item }">

                          <p>
                            <a v-if="item.file" :download="item.file.fileName" :href="item.file.filePath"
                              target="_blank">{{ item.file.fileName }}</a>
                          </p>
                        </template>
                      </el-table-column>
                      <el-table-column label="提交日期">
                        <template #default="{ row: item }">{{
                          parseTime(item.createTime, "{yyyy}-{mm}-{dd}")
                          }}</template>
                      </el-table-column>
                      <el-table-column>
                        <template #default="{ row: item }">
                          <el-button type="primary" icon="el-icon-edit" size="mini" circle
                            @click="manageShow(unionCheckSetting, item)"></el-button>

                          <el-popconfirm title="确认删除吗!?" @confirm="
                            removeFromList(form.unionCheckInfos, item)
                            ">
                            <template #reference>
                              <el-button type="danger" size="mini" circle>
                                <el-tooltip content="删除">
                                  <i class="el-icon-delete"></i>
                                </el-tooltip>
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="资金支出" name="fund" v-if="setting.active_Name == 'fund'">
          <FundRunSubmit v-model="fundWatches[i]" v-for="(item, i) in fundWatches" :id="item.id" :key="item.id"
            :ref="(el) => setFundSubmitInRefs(el, item.id)" style="width: 100%"></FundRunSubmit>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <BiddingManage :setting="biddingManageSetting" @change="(arg) => onListManged(form.biddingInfos, arg)"
      v-if="biddingManageSetting.visible"></BiddingManage>

    <ContractMange :setting="contractMangeSetting" @change="(arg) => onListManged(form.contractInfos, arg)"
      v-if="contractMangeSetting.visible"></ContractMange>

    <FirstCheckMange :setting="firstCheckSetting" @change="(arg) => onListManged(form.firstCheckInfos, arg)"
      v-if="firstCheckSetting.visible"></FirstCheckMange>

    <ProjectStartMange :setting="projectStartMangeSetting" @change="(arg) => onListManged(form.projectStartInfos, arg)"
      v-if="projectStartMangeSetting.visible"></ProjectStartMange>

    <UnionCheckMange :setting="unionCheckSetting" @change="(arg) => onListManged(form.unionCheckInfos, arg)"
      v-if="unionCheckSetting.visible"></UnionCheckMange>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" @click="tempSave" :loading="isProcessing">暂存</el-button>
        <el-button type="success" @click="save" :loading="isProcessing">提交</el-button>
        <!-- <el-popconfirm title="确认提交申请验收吗！？" @confirm="confirm()">
          <template #reference>
            <el-button type="primary">申请验收</el-button>
          </template>
</el-popconfirm>-->
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { parseTime } from "@/utils/index.js";
import { delayExec } from "@/utils/util.js";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import FundRunSubmit from "./fundRunSubmit.vue";
import BiddingManage from "./biddingManage.vue";
import ContractMange from "./contractMange.vue";
import FirstCheckMange from "./firstCheckMange.vue";
import UnionCheckMange from "./unionCheckMange.vue";
import ProjectStartMange from "./projectStartMange.vue";

import {
  numberRules,
  numberRulesNoRequired,
  inputChangeEmptyToNull,
} from "@/model/fundFormRules";
import { useFundWatchAction } from "@/network/fundWatch";
import {
  fundWatchProjectDoStatusDic,
  fundWatchSourceDic,
} from "@/network/lookUp.ts";

import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from "vue";
import { right } from "@popperjs/core";
import { promises } from "dns";

export default defineComponent({
  components: {
    FileuploadListV1,
    FundRunSubmit,
    BiddingManage,
    ContractMange,
    FirstCheckMange,
    ProjectStartMange,
    SelectUnit,
    UnionCheckMange,
  },
  emits: ["change"],
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const user: any = inject("user");
    const refForm2 = ref(null);
    const refForm3 = ref(null);
    const refForm4 = ref(null);
    const rules = ref({
      setupDoc_Title: {
        required: true,
        message: "计划书批复名称不能为空!",
        trigger: "blur",
      },
      setupDoc: {
        required: true,
        message: "计划书批复文号不能为空!",
        trigger: "blur",
      },
      proposalDoc: {
        required: true,
        message: "技术方案批复文号不能为空!",
        trigger: "blur",
      },
      proposalDoc_Title: {
        required: true,
        message: "技术方案方案名称不能为空!",
        trigger: "blur",
      },
      project_DoStatus: {
        required: true,
        message: "进展状态不能为空!",
        trigger: "change",
      },
      questionDetail: {
        required: true,
        message: "进展描述不能为空!",
        trigger: "blur",
      },
      project_Fund_Source: {
        required: true,
        message: "资金来源不能为空!",
        trigger: "blur",
      },
      project_DoUnit: {
        required: true,
        message: "施工单位不能为空!",
        trigger: "blur",
      },
      project_DesignUnit: {
        required: true,
        message: "设计单位不能为空!",
        trigger: "blur",
      },
      project_WatchUnit: {
        required: true,
        message: "监理单位不能为空!",
        trigger: "blur",
      },
      project_ManageUnit: {
        required: true,
        message: "管理单位不能为空!",
        trigger: "blur",
      },
      project_Fund_Amount: numberRules,
    });
    const isLoading = ref(true);
    const activeName = ref("project");
    const [
      isProcessing,
      ,
      ,
      ,
      projectUpdate,
      ,
      getfundWatches,
      projectFind,
      ,
      projectUpdateTmp,
      logFundWatchTmpSaveErr,
    ] = useFundWatchAction();

    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });

    const biddingManageSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const contractMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const projectStartMangeSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });
    const firstCheckSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const unionCheckSetting = reactive({
      visible: false,
      action: null,
      form: null,
    });

    const activeNames = ref(["1", "2", "3", "4"]);
    const attachmentType = ref(null);
    const form = reactive({
      id: null,
      fundWatches: null,
      heritageUnit_Name: null,
      biddingInfos: [],
      contractInfos: [],
      projectStartInfos: [],
      firstCheckInfos: [],
      unionCheckInfos: [],
      attachList: []
    });

    const fundWatches = ref([]);

    const { visible, id } = toRefs(props.setting as any);
    const fundRunSubmitRef = ref([]);
    const setFundSubmitInRefs = (el, id) => {
      if (el != null && !fundRunSubmitRef.value.some((p) => p == el)) {
        //console.log(el, "bbb");
        fundRunSubmitRef.value.push(el);
      }
    };
    const tempSave = () => {
      // console.log(fundWatches.value);
      // const d = Object.assign({ fundWatches: fundWatches.value }, form);
      // localStorage.setItem("_fundSubmit_tmp_" + form.id, JSON.stringify(d));
      // ElMessage.success({ message: "数据已保存", type: "success" });

      const data = Object.assign(
        {
          _updateTime: new Date(),
          _updateUser: user.name,
          fundWatches: fundWatches.value,
          tmpData: null,
        },
        form
      );
      delete data.tmpData;

      if (!data.id) {
        ElMessage.error({
          message: "暂存失败！数据异常，请刷新页面后重新填报！",
          type: "error",
          duration: 5000,
        });
        return false;
      }
      projectUpdateTmp(id.value, data).then((p) => {
        if (localStorage.getItem("_fundSubmit_tmp_" + data.id)) {
          localStorage.setItem("_fundSubmit_tmp_" + data.id + "_Expire", "Y");
        }
      });
    };
    const manageShow = (setting, item, action = item == null ? "add" : "update") => {
      setting.action = action;
      setting.form = item;
      setting.visible = true;
    };

    const removeFromList = (list, item) => {
      const index = list.findIndex((p) => item == p);
      if (index >= -1) {
        list.splice(index, 1);
      }
    };

    const onListManged = (list, { action, data }) => {
      //console.log(list, action, data);
      if (action == "add") {
        data.createTime = new Date();
        // data.createTime = parseTime(new Date(), "{yyyy}-{mm}-{dd}");
        // console.log(data,parseTime(new Date(), "{yyyy}-{mm}-{dd}"))
        list.push(data);
      }
    };

    const checkSetFundwatches = (data, isCheckSetFundwatches) => {
      return isCheckSetFundwatches
        ? delayExec(10).then(() => {
          const watches = data.fundWatches;
          //console.log(watches, "111");
          const errors = watches.filter((p) => p.project_Id != data.id);
          if (errors.length > 0) {
            logFundWatchTmpSaveErr(form);
            return getfundWatches(data.id).then((rights) => {
              if (errors.length == 1 && rights.length == 1) {
                rights[0].fundFileCode = errors[0].fundFileCode;
                rights[0].fundGetDate = errors[0].fundGetDate;
                rights[0].funGetAmount = errors[0].funGetAmount;
                rights[0].project_FundDo_Status =
                  errors[0].project_FundDo_Status;
                rights[0].currentSpend = errors[0].currentSpend;
                rights[0].fundDoSlowReason = errors[0].fundDoSlowReason;
              }
              data.fundWatches = rights;
              return data.fundWatches;
            });
          } else {
            return Promise.resolve(watches);
          }
        })
        : getfundWatches(data.id);
    };

    onMounted(async () => {
      isLoading.value = true;
      activeName.value = props.setting.active_Name || "project";

      try {
        //服务器数据
        const projectData = await projectFind(props.setting.id);
        //本地缓存
        const tempData = localStorage.getItem(
          "_fundSubmit_tmp_" + props.setting.id
        );
        //本地缓存过期
        const tempDataCheckExpire = localStorage.getItem(
          "_fundSubmit_tmp_" + props.setting.id + "_Expire"
        );
        //是否缓存子数据校验
        let isCheckSetFundwatches = false;
        if (tempData != null && tempDataCheckExpire == null) {
          Object.assign(form, JSON.parse(tempData));
          isCheckSetFundwatches = true;
        }
        //云端缓存
        else if (projectData.tmpData != null) {
          Object.assign(form, JSON.parse(projectData.tmpData));
          isCheckSetFundwatches = true;
        }
        //无缓存
        else {
          Object.assign(form, projectData);
          isCheckSetFundwatches = false;
        }
        if (form.fundWatches == null || form.fundWatches.length == 0) {
          form.fundWatches = await getfundWatches(form.id);
        }
        //加载子数据
        fundWatches.value = await checkSetFundwatches(
          form,
          isCheckSetFundwatches
        );
        isLoading.value = false;

        selectUnitSetting.heritage_Name = form.heritageUnit_Name;

        if (form.attachList == null) form.attachList = [];
      } catch (p) {
        //console.log(p)
        visible.value = false;
        ElMessage.error({
          message: "数据加载失败，请尝试重新加载！",
          type: "error",
        });
      }
    });

    const save = () => {

      if (activeName.value == "project") {

        Promise.all([refForm2.value?.validate(), refForm3.value?.validate(), refForm4.value?.validate()]).then(() => {
          projectUpdate(id.value, form).then((o) => {
            if (localStorage.getItem("_fundSubmit_tmp_" + form.id)) {
              localStorage.setItem(
                "_fundSubmit_tmp_" + form.id + "_Expire",
                "Y"
              );
            }
            // localStorage.removeItem("_fundSubmit_tmp_" + form.id);

            ElMessageBox.confirm(
              "数据已提交成功！是否关闭当前填报窗口？",
              "操作提示",
              { type: "success" }
            )
              .then(() => {
                visible.value = false;
                emit("change");
              })
              .catch(() => {

                form.biddingInfos = o.biddingInfos;
                form.contractInfos = o.contractInfos;
                form.projectStartInfos = o.projectStartInfos;
                form.firstCheckInfos = o.firstCheckInfos;
                form.attachList = o.attachList;
                form.unionCheckInfos = o.unionCheckInfos;
                emit("change");
              });
          });
        })
          .catch((err) => {
            ElMessage.error({
              message:
                "提交失败！各处金额、面积仅需填写数字，无需填写 元/万元/米/平方米 等相关文字，请检查输入表单提示红框处内容后再次尝试提交！",
              type: "error",
              duration: 5000,
            });
          });

      }
      else {
        const listSave = fundRunSubmitRef.value.map((p) => p.save());
        Promise.all(listSave).then(p => {

          ElMessageBox.confirm(
            "数据已提交成功！是否关闭当前填报窗口？",
            "操作提示",
            { type: "success" }
          )
            .then(() => {
              visible.value = false;
              emit("change");
            })

        }).catch((e) => {
          ElMessage.error({
            message:
              "资金支出信息提交失败！各处金额、面积仅需填写数字，无需填写 元/万元/米/平方米 等相关文字，请检查输入表单提示红框处内容后再次尝试提交！",
            type: "error",
            duration: 5000,
          });
        });

      }




    };

    const selectUnitChange = (item) => {
      form.heritageUnit_Name = item.heritage_Name;
    };

    return {
      selectUnitSetting,
      selectUnitChange,
      refForm2,
      refForm3,
      refForm4,
      rules,
      inputChangeEmptyToNull,
      isLoading,
      tempSave,
      activeName,
      parseTime,
      isProcessing,
      manageShow,
      removeFromList,
      save,
      biddingManageSetting,
      contractMangeSetting,
      projectStartMangeSetting,
      firstCheckSetting,
      unionCheckSetting,
      onListManged,

      activeNames,
      attachmentType,
      visible,
      confirm,
      form,
      fundWatches,
      fundRunSubmitRef,
      setFundSubmitInRefs,
      fundWatchProjectDoStatusDic,
      fundWatchSourceDic,
    };
  },
});
</script>

<style scoped></style>
