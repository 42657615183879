
import { getToken } from "@/utils/auth";
import { strMBFormat, previewOrDownload } from "@/utils/util";
import settings from "@/settings";
import { ElMessage } from "element-plus";
import { defineComponent, toRefs, ref, reactive, watch } from "vue";

export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["remove", "upload", "progress"],

  setup(props, { emit, expose }) {
    const refUpload = ref(null);

    const { accpet = ref(settings.attachment.accept), fileEmbed = ref(null), showTip = ref(true), btnText = ref('点击上传'), urlKey = ref("url"), nameKey = ref("name"), isFileUploading = ref(false) } = toRefs(props.setting);



    const onExceed = () => {
      ElMessage.error(
        `仅允许同时上传1份文件！`
      );
    };
    const onBeforeUpload = (file) => {
      if (fileEmbed.value != null) {
        ElMessage.error(
          `仅允许上传1份附件，如需修改请删除原有后重新上传！`
        );
        return false;
      }
      return true;
    }
    const onRemoveFile = () => {
      fileEmbed.value = null;
      emit("remove", fileEmbed);
    };
    const onSuccess = (response, file) => {
      if (response.code == 200) {
        isFileUploading.value = false;
        file.url = response.data;
        const arg = { [urlKey.value]: response.data, [nameKey.value]: file.name };
        console.log(arg);
        emit("upload", arg);
        refUpload.value.clearFiles();
        fileEmbed.value = arg;
      }
    };
    const onProgress = () => {
      isFileUploading.value = true;
      emit("progress");
    };

    expose({
      clear: () => {
        fileEmbed.value = null;
      }
    })



    return {
      btnText,
      action: settings.uploadAction,
      showTip,
      refUpload,
      accpet,
      onBeforeUpload,
      onExceed,
      onRemoveFile,
      onSuccess,
      fileEmbed,
      onProgress,
      urlKey,
      nameKey
    };
  },
});
