<template>
  <statusSubmitEdit
    :setting="setting"
    :action="action"
    v-if="action == 'edit' && setting.visible"
    @change="$emit('change')"
  ></statusSubmitEdit>
  <statusSubmitView
    :action="action"
    v-if="action == 'view' && setting.visible"
    :setting="setting"
  ></statusSubmitView>
</template>

<script lang="ts">
import { defineComponent, watch, ref, onMounted } from "vue";
import statusSubmitEdit from "./statusSubmitEdit.vue";
import statusSubmitView from "./statusSubmitView.vue";
export default defineComponent({
  components: {
    statusSubmitEdit,
    statusSubmitView,
  },
  props: ["action", "setting"],
  emits: ["change"],
  setup(props, { emit }) {
    //console.log(props);
    // const refStatusSubmitObj = ref(null);
    // watch(
    //   () => props.setting.visible,
    //   (v) => {
    //     if (v) {
    //       setTimeout(() => {
    //         if (refStatusSubmitObj.value != null)
    //           refStatusSubmitObj.value.loadData();
    //         else {
    //           const unwatch = watch(refStatusSubmitObj, () => {
    //             refStatusSubmitObj.value.loadData();
    //             unwatch();
    //           });
    //         }
    //       }, 1);
    //     }
    //   }
    // );

    return {};
  },
});
</script>

<style scoped></style>
